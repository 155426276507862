import {
  getPortfolioFromLocalStorage,
  getPortfolioUpdatedAtFromLocalStorage,
} from '@/localStorage'
import type { Portfolio, PortfolioPageData } from '@/types'
import { changePortfolio } from './changePortfolio'
import { createPortfolio } from './createPortfolio'
import { getPortfolioDbFile } from './getPortfolioDbFile'
import { getPortfolios } from './getPortfolios'
import { syncPortfolio } from './syncPortfolio'

export type SetupPortfolioReturnTypes = {
  portfolios: readonly Portfolio[]
  portfolio: PortfolioPageData
}

let cache:
  | {
      timestamp: number
      id: string
      data: SetupPortfolioReturnTypes
    }
  | undefined

export const setupPortfolio = async (params: {
  token: string
  idByQuery?: string
}): Promise<SetupPortfolioReturnTypes> => {
  const { token, idByQuery } = params

  try {
    // query 優先で portfolio id を決定
    // query がない場合は localStorage から取得
    const localPortfolio = getPortfolioFromLocalStorage()
    const localId = localPortfolio?.uuid
    const id = idByQuery ?? localId

    // 1分以内の同一リクエストはキャッシュを返す
    if (
      cache !== undefined &&
      cache.timestamp > Date.now() - 60_000 &&
      cache.id === id
    ) {
      return cache.data
    }

    // portfolio list と local id に紐づく portfolio db を並列取得
    const lastUpdatedAt = getPortfolioUpdatedAtFromLocalStorage()
    const [portfolios, dbFile] = await Promise.all([
      getPortfolios({ token }),
      localId === undefined ||
      (idByQuery !== undefined && idByQuery !== localId)
        ? undefined
        : getPortfolioDbFile({ token, id: localId, timestamp: lastUpdatedAt }),
    ])

    // create / change / sync いずれかの処理で return data 作成
    let data: SetupPortfolioReturnTypes
    const existingLocalId = portfolios.some((p) => p.uuid === localId)
      ? localId
      : undefined
    const existingIdQuery = portfolios.some((p) => p.uuid === idByQuery)
      ? idByQuery
      : undefined
    if (
      existingLocalId !== undefined &&
      (existingIdQuery === undefined || existingIdQuery === existingLocalId)
    ) {
      // 指定された id query が local id と一致、かつ local id が portfolio list に存在する場合は sync 処理
      data = await syncPortfolio({
        token,
        portfolios,
        id: existingLocalId,
        dbFile,
      })
    } else if (portfolios.length > 0) {
      // local id とは別の portfolio に変更する場合は change 処理
      data = await changePortfolio({
        token,
        portfolios,
        id: existingIdQuery ?? portfolios[0].uuid,
      })
    } else {
      // portfolio list が空の場合は create 処理
      data = await createPortfolio({ token, portfolios })
    }

    // cache に保存
    cache = {
      timestamp: Date.now(),
      id: data.portfolio.uuid,
      data,
    }

    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}
