import type { Action } from '@0xtorch/core'
import {
  type TransactionDetail,
  createSolanaActionSource,
  createSolanaAddressId,
} from '@0xtorch/solana'
import { divideArrayIntoChunks } from '@pkg/basic'
import { eq, inArray, sql } from 'drizzle-orm'
import {
  actionAccountRelationColumnCount,
  actionColumnCount,
  actionSourceColumnCount,
  actionTransferColumnCount,
  cryptoCurrencyColumnCount,
  nftColumnCount,
  sqliteMaxCompoundSelectCount,
  sqliteMaxHostParameterCount,
} from '../../constants'
import { actionsToInsertSqls } from '../../createSql/actionsToInsertSqls'
import {
  accountSolanaSignatureTable,
  accountTable,
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionAccountRelationTable,
  actionSourceTable,
  actionTable,
  actionTransferTable,
  cryptoCurrencyTable,
  nftTable,
  portfolioTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../../utils'

type SaveSolanaTxAndActionsParameters = {
  database: DatabaseWithTransaction
  transactions: readonly TransactionDetail[]
  actions: readonly Action[]
}

/** Solana transaction から生成したデータを保存
 * - portfolio.isTxGenerated を false に更新
 * - transaction に関連する accountSolanaSignature.analyzed を true に更新
 * - transaction を actionSource に保存 (upsert)
 * - actionSource に関連する既存データを削除
 *   - action account relation
 *   - accounting transaction transfer
 *   - accounting transaction
 *   - action transfer
 *   - action
 * - action 関連データを保存
 *   - crypto currency
 *   - nft
 *   - action
 *   - action transfer
 *   - action account relation
 * - account.syncing を更新
 */
export const saveSolanaTxAndActions = async ({
  database: { database, transaction },
  transactions,
  actions,
}: SaveSolanaTxAndActionsParameters) => {
  if (transactions.length === 0 && actions.length === 0) {
    return
  }

  const signatures: string[] = []
  const sources: string[] = []
  for (const transaction of transactions) {
    const signature = transaction.transaction.signatures[0]
    signatures.push(signature)
    sources.push(
      createSolanaActionSource({
        signature,
      }),
    )
  }

  const accountRows = await database
    .select({ id: accountTable.id, address: accountTable.solanaAddress })
    .from(accountTable)
    .where(eq(accountTable.type, 'solana'))
  const accounts: { id: number; address: string }[] = []
  for (const row of accountRows) {
    if (row.address === null) {
      continue
    }
    accounts.push({ id: row.id, address: row.address })
  }
  const actionAccountRelations = generateActionAccountRelations({
    transactions,
    actions,
    accounts,
  })

  const { actionSqls, actionTransferSqls, cryptoSqls, nftSqls } =
    actionsToInsertSqls(actions)

  await transaction([
    (tx) => [
      // portfolio.isTxGenerated を false に更新
      tx
        .update(portfolioTable)
        .set({ isTxGenerated: false }),
      // transaction に関連する accountSolanaSignature.analyzed を true に更新
      ...divideArrayIntoChunks(signatures, sqliteMaxHostParameterCount - 2).map(
        (chunk) =>
          tx
            .update(accountSolanaSignatureTable)
            .set({ analyzed: true })
            .where(inArray(accountSolanaSignatureTable.signature, [...chunk])),
      ),
      // transaction を actionSource に保存 (upsert)
      ...divideArrayIntoChunks(
        transactions,
        getMaxInsertRowCount(actionSourceColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(actionSourceTable)
          .values(
            chunk.map((transaction) => ({
              id: createSolanaActionSource({
                signature: transaction.transaction.signatures[0],
              }),
              type: 'solana' as const,
              solanaSignature: transaction.transaction.signatures[0],
              solanaStatus:
                transaction.meta?.err === null
                  ? ('success' as const)
                  : ('reverted' as const),
            })),
          )
          .onConflictDoUpdate({
            target: [actionSourceTable.id],
            set: conflictUpdateAllExcept(actionSourceTable, ['id']),
          }),
      ),
      // actionSource に関連する action account relation 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionAccountRelationTable).where(
            inArray(
              actionAccountRelationTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // actionSource に関連する accounting transaction transfer 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTransferTable).where(
            inArray(
              accountingTransactionTransferTable.transactionId,
              tx
                .select({ id: accountingTransactionTable.id })
                .from(accountingTransactionTable)
                .leftJoin(
                  actionTable,
                  eq(accountingTransactionTable.actionId, actionTable.id),
                )
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // actionSource に関連する accounting transaction 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTable).where(
            inArray(
              accountingTransactionTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // actionSource に関連する action transfer 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionTransferTable).where(
            inArray(
              actionTransferTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // actionSource に関連する action 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx
            .delete(actionTable)
            .where(inArray(actionTable.sourceId, [...chunk])),
      ),
    ],
    (tx) => [
      // crypto
      ...divideArrayIntoChunks(
        cryptoSqls,
        getMaxInsertRowCount(cryptoCurrencyColumnCount),
      ).map((chunk) =>
        tx
          .insert(cryptoCurrencyTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // nft
      ...divideArrayIntoChunks(
        nftSqls,
        getMaxInsertRowCount(nftColumnCount),
      ).map((chunk) =>
        tx
          .insert(nftTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // action
      ...divideArrayIntoChunks(
        actionSqls,
        // SQLITE_MAX_COMPOUND_SELECT を意識して数を調整
        Math.min(
          getMaxInsertRowCount(actionColumnCount + 3),
          Math.floor(sqliteMaxCompoundSelectCount / 3),
        ),
      ).map((chunk) => tx.insert(actionTable).values([...chunk])),
      // action transfer
      ...divideArrayIntoChunks(
        actionTransferSqls,
        getMaxInsertRowCount(actionTransferColumnCount),
      ).map((chunk) => tx.insert(actionTransferTable).values([...chunk])),
      // action account relation
      ...divideArrayIntoChunks(
        actionAccountRelations,
        getMaxInsertRowCount(actionAccountRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(actionAccountRelationTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // account.syncing を analyzed=false の accountSolanaSignature の存在に合わせて更新
      ...accounts.map(({ id }) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountSolanaSignatureTable} WHERE ${accountSolanaSignatureTable.accountId} = ${id} AND ${accountSolanaSignatureTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
    ],
  ])
}

/** transaction と action から actionAccountRelation data を作成
 * - action transfer の from, to に account address が含まれていれば account に関連づける
 * - transaction signer に account address が含まれていれば account に関連づける
 */
const generateActionAccountRelations = (params: {
  transactions: readonly TransactionDetail[]
  actions: readonly Action[]
  accounts: readonly { id: number; address: string }[]
}): readonly { actionId: string; accountId: number }[] => {
  const { transactions, actions, accounts } = params

  const addressMap = new Map<string, number>(
    accounts.map(({ address, id }) => [address, id]),
  )
  const solanaAddressIdMap = new Map<string, number>(
    accounts.map(({ address, id }) => [createSolanaAddressId({ address }), id]),
  )
  const sourceSignersMap = new Map<string, string[]>(
    transactions.map((tx) => [
      createSolanaActionSource({ signature: tx.transaction.signatures[0] }),
      tx.transaction.message.accountKeys
        .map((key) => (key.signer ? key.pubkey : undefined))
        .filter((signer): signer is string => signer !== undefined),
    ]),
  )

  const relationMap = new Map<string, { actionId: string; accountId: number }>()
  for (const action of actions) {
    const actionId = `${action.source}_${action.order}`
    // signers と一致する account との relation data 作成
    const signers = sourceSignersMap.get(action.source)
    for (const signer of signers ?? []) {
      const signerAccountId = addressMap.get(signer)
      if (signerAccountId !== undefined) {
        const id = `${actionId}/${signerAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, { actionId, accountId: signerAccountId })
        }
      }
    }

    // action transfer の from, to と一致する account を追加
    for (const transfer of action.transfers) {
      const transferFromAccountId =
        transfer.from === undefined
          ? undefined
          : solanaAddressIdMap.get(transfer.from)
      if (transferFromAccountId !== undefined) {
        const id = `${actionId}/${transferFromAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferFromAccountId,
          })
        }
      }
      const transferToAccountId =
        transfer.to === undefined
          ? undefined
          : solanaAddressIdMap.get(transfer.to)
      if (transferToAccountId !== undefined) {
        const id = `${actionId}/${transferToAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferToAccountId,
          })
        }
      }
    }
  }

  return [...relationMap.values()]
}
