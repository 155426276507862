import { assetApiEndpoint } from '@/environment'
import {
  createAssetFetchClient,
  createCryptoCurrencyDataSource,
} from '@pkg/datasource-client'

export const assetFetchClient = createAssetFetchClient({
  endpoint: assetApiEndpoint,
})

export const cryptoCurrencyDataSource =
  createCryptoCurrencyDataSource(assetFetchClient)
