import {
  getPortfolioFromLocalStorage,
  setPortfolioUpdatedAtToLocalStorage,
} from '@/localStorage'
import type { RunToDatabase } from '@/types'
import { type DatabaseWithTransaction, schema } from '@pkg/database-portfolio'
import { drizzle } from 'drizzle-orm/sqlite-proxy'
import { SQLocal } from 'sqlocal'
import { SQLocalDrizzle } from 'sqlocal/drizzle'

let usingDatabase = false
let alreadyAlerted = false
let isForbiddenUsingDatabase = false

export const forbidUsingDatabase = () => {
  isForbiddenUsingDatabase = true
}

export const getIsForbiddenUsingDatabase = () => isForbiddenUsingDatabase

const createPortfolioDbFileName = (id: string): string => `${id}.sqlite`

const getDatabase = (
  id: string,
): { database: DatabaseWithTransaction; destroy: () => Promise<void> } => {
  const { driver, batchDriver, transaction, destroy } = new SQLocalDrizzle(
    createPortfolioDbFileName(id),
  )
  const database = drizzle(driver, batchDriver, { schema })
  return {
    database: {
      database,
      transaction: async (querySteps) =>
        await transaction(async (tx) => {
          for (const queryStep of querySteps) {
            await Promise.all(
              queryStep(database).map((query) => tx.query(query)),
            )
          }
        }),
    },
    destroy,
  }
}

export const getDatabaseFile = async (id: string): Promise<File> => {
  if (!usingDatabase) {
    usingDatabase = true
  } else {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!usingDatabase) {
          usingDatabase = true
          clearInterval(interval)
          resolve(undefined)
        }
      }, 20)
    })
  }
  if (isForbiddenUsingDatabase) {
    usingDatabase = false
    throw new Error('Connection to the database is forbidden')
  }
  const portfolio = getPortfolioFromLocalStorage()
  if (portfolio === undefined || portfolio.uuid !== id) {
    if (!alreadyAlerted) {
      alreadyAlerted = true
      alert('The opened portfolio has been changed!')
    }
    usingDatabase = false
    window.location.replace(window.location.pathname)
  }
  const { getDatabaseFile: getDBFile, destroy } = new SQLocal(
    createPortfolioDbFileName(id),
  )
  try {
    const file = await getDBFile()
    return file
  } finally {
    await destroy()
    usingDatabase = false
  }
}

export const deleteDatabase = async (id: string) => {
  if (!usingDatabase) {
    usingDatabase = true
  } else {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!usingDatabase) {
          usingDatabase = true
          clearInterval(interval)
          resolve(undefined)
        }
      }, 20)
    })
  }
  if (isForbiddenUsingDatabase) {
    usingDatabase = false
    throw new Error('Connection to the database is forbidden')
  }
  const { deleteDatabaseFile, destroy } = new SQLocal(
    createPortfolioDbFileName(id),
  )
  try {
    await deleteDatabaseFile()
  } finally {
    await destroy()
    usingDatabase = false
  }
}

export const overwriteDatabase = async (id: string, file: Blob) => {
  if (!usingDatabase) {
    usingDatabase = true
  } else {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!usingDatabase) {
          usingDatabase = true
          clearInterval(interval)
          resolve(undefined)
        }
      }, 20)
    })
  }
  if (isForbiddenUsingDatabase) {
    usingDatabase = false
    throw new Error('Connection to the database is forbidden')
  }
  const { overwriteDatabaseFile, destroy } = new SQLocal(
    createPortfolioDbFileName(id),
  )
  try {
    await overwriteDatabaseFile(file)
  } finally {
    await destroy()
    usingDatabase = false
  }
}

export const createReadFromDatabase =
  (id: string): RunToDatabase =>
  async (run) => {
    if (!usingDatabase) {
      usingDatabase = true
    } else {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!usingDatabase) {
            usingDatabase = true
            clearInterval(interval)
            resolve(undefined)
          }
        }, 20)
      })
    }
    if (isForbiddenUsingDatabase) {
      usingDatabase = false
      throw new Error('Connection to the database is forbidden')
    }
    const portfolio = getPortfolioFromLocalStorage()
    if (portfolio === undefined || portfolio.uuid !== id) {
      if (!alreadyAlerted) {
        alreadyAlerted = true
        alert('The opened portfolio has been changed!')
      }
      usingDatabase = false
      window.location.replace(window.location.pathname)
    }
    const { database, destroy } = getDatabase(id)
    try {
      const result = await run(database)
      return result
    } finally {
      await destroy()
      usingDatabase = false
    }
  }

export const createWriteToDatabase =
  (id: string): RunToDatabase =>
  async (run) => {
    if (!usingDatabase) {
      usingDatabase = true
    } else {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!usingDatabase) {
            usingDatabase = true
            clearInterval(interval)
            resolve(undefined)
          }
        }, 20)
      })
    }
    if (isForbiddenUsingDatabase) {
      usingDatabase = false
      throw new Error('Connection to the database is forbidden')
    }
    const portfolio = getPortfolioFromLocalStorage()
    if (portfolio === undefined || portfolio.uuid !== id) {
      if (!alreadyAlerted) {
        alreadyAlerted = true
        alert('The opened portfolio has been changed!')
      }
      usingDatabase = false
      window.location.replace(window.location.pathname)
    }
    const { database, destroy } = getDatabase(id)
    try {
      const result = await run(database)
      setPortfolioUpdatedAtToLocalStorage(Date.now())
      return result
    } finally {
      await destroy()
      usingDatabase = false
    }
  }
