import { divideArrayIntoChunks } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../../constants'
import {
  accountPolkadotExtrinsicRelationTable,
  accountTable,
  actionSourceTable,
  actionTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

type IgnoreLockedPolkadotBlocksParameters = {
  database: DatabaseWithTransaction
  chainId: number
  blockNumbers: readonly number[]
}

/** locked = true の action に紐づく blockNumbers を取得
 * - locked = true の action に紐づく blockNumber 関連の account polkadot extrinsic relation は　analyzed=true に更新
 * - account.syncing を account polkadot extrinsic relation の更新に合わせて更新
 */
export const ignoreLockedPolkadotBlocks = async ({
  database: { database, transaction },
  chainId,
  blockNumbers,
}: IgnoreLockedPolkadotBlocksParameters): Promise<Set<number>> => {
  if (blockNumbers.length === 0) {
    return new Set()
  }

  const accountIdRows = await database
    .select({ id: accountTable.id })
    .from(accountTable)
    .where(eq(accountTable.polkadotChainId, chainId))
  const accountIds = accountIdRows.map(({ id }) => id)

  // locked=true の action に紐づく blockNumbers を取得
  const lockedBlockNumberRows = await database
    .selectDistinct({
      blockNumber: actionSourceTable.polkadotBlockNumber,
    })
    .from(actionSourceTable)
    .innerJoin(actionTable, eq(actionSourceTable.id, actionTable.sourceId))
    .where(
      and(
        eq(actionTable.locked, true),
        eq(actionSourceTable.polkadotChainId, chainId),
        inArray(actionSourceTable.polkadotBlockNumber, [...blockNumbers]),
      ),
    )
  const lockedBlockNumberSet = new Set<number>()
  for (const row of lockedBlockNumberRows) {
    if (row.blockNumber === null) {
      continue
    }
    lockedBlockNumberSet.add(row.blockNumber)
  }

  await transaction([
    (tx) => [
      // account polkadot extrinsic の analyzed を true に更新
      ...divideArrayIntoChunks(
        [...lockedBlockNumberSet],
        sqliteMaxHostParameterCount - accountIds.length - 1,
      ).map((chunk) =>
        tx
          .update(accountPolkadotExtrinsicRelationTable)
          .set({ analyzed: true })
          .where(
            and(
              inArray(
                accountPolkadotExtrinsicRelationTable.accountId,
                accountIds,
              ),
              inArray(accountPolkadotExtrinsicRelationTable.blockNumber, [
                ...chunk,
              ]),
            ),
          ),
      ),
    ],
    (tx) => [
      // account.syncing を更新
      ...accountIdRows.map(({ id }) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountPolkadotExtrinsicRelationTable} WHERE ${accountPolkadotExtrinsicRelationTable.accountId} = ${id} AND ${accountPolkadotExtrinsicRelationTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
    ],
  ])

  return lockedBlockNumberSet
}
