import type { FiatCurrency } from '@0xtorch/core'
import { type Action, divideArrayIntoChunks } from '@pkg/basic'
import type { SQL } from 'drizzle-orm'
import { and, asc, eq, gte, inArray, lte } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../constants'
import { parseToAction } from '../parsers/action'
import { actionTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type SelectActionsParameters = {
  readonly database: DatabaseWithTransaction
  readonly sourceList?: readonly string[]
  readonly from?: number
  readonly to?: number
  readonly isCompleteTransactionGeneration?: boolean
  readonly isLocked?: boolean
  readonly limit?: number
  readonly fiatCurrencyList: readonly FiatCurrency[]
  readonly fiatCurrency: FiatCurrency
}

export const selectActions = async ({
  database: { database },
  sourceList,
  from,
  to,
  isCompleteTransactionGeneration,
  isLocked,
  limit,
  fiatCurrencyList,
  fiatCurrency,
}: SelectActionsParameters): Promise<readonly Action[]> => {
  const whereConditions: SQL<unknown>[] = []
  if (from !== undefined) {
    whereConditions.push(gte(actionTable.timestamp, new Date(from)))
  }
  if (to !== undefined && to > 0) {
    whereConditions.push(lte(actionTable.timestamp, new Date(to)))
  }
  if (isCompleteTransactionGeneration !== undefined) {
    whereConditions.push(
      eq(actionTable.generatedTx, isCompleteTransactionGeneration),
    )
  }
  if (isLocked !== undefined) {
    whereConditions.push(eq(actionTable.locked, isLocked))
  }

  if (sourceList !== undefined && sourceList.length > 0) {
    const sourceListChunkList = divideArrayIntoChunks(
      sourceList,
      sqliteMaxHostParameterCount - whereConditions.length - 1,
    )
    const results = await Promise.all(
      sourceListChunkList.map((sources) =>
        (async () => {
          const where =
            whereConditions.length === 0
              ? inArray(actionTable.sourceId, [...sources])
              : and(
                  inArray(actionTable.sourceId, [...sources]),
                  ...whereConditions,
                )
          return await database.query.actionTable.findMany({
            where,
            limit: limit !== undefined && limit > 0 ? limit : undefined,
            with: {
              actionTransferTable: {
                with: {
                  cryptoCurrencyTable: true,
                  nftTable: true,
                },
              },
              nftTable: true,
            },
          })
        })(),
      ),
    )
    const result = results.flat()
    return result.map((data) =>
      parseToAction(data, fiatCurrencyList, fiatCurrency),
    )
  }

  let where: SQL<unknown> | undefined
  if (whereConditions.length === 0) {
    where = undefined
  } else if (whereConditions.length === 1) {
    where = whereConditions[0]
  } else {
    where = and(...whereConditions)
  }
  const result = await database.query.actionTable.findMany({
    where,
    limit: limit !== undefined && limit > 0 ? limit : undefined,
    orderBy: [asc(actionTable.timestamp)],
    with: {
      actionTransferTable: {
        with: {
          cryptoCurrencyTable: true,
          nftTable: true,
        },
      },
      nftTable: true,
    },
  })
  return result.map((data) =>
    parseToAction(data, fiatCurrencyList, fiatCurrency),
  )
}
