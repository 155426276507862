import type { AssetFetchClient } from '@pkg/datasource-client'

type AnalyzerJson = {
  readonly programId: string
  readonly json: unknown
}

export const createAssetsMiddleware = (() => {
  const mut_analyzerJsonesCache: AnalyzerJson[] = []

  return (client: AssetFetchClient) => ({
    getAnalyzerJson: async (programId: string) => {
      const cached = mut_analyzerJsonesCache.find(
        (item) => item.programId === programId,
      )
      if (cached !== undefined) {
        return cached.json
      }
      const response = await client.get(`/solanas/analyzers/${programId}.json`)
      if (!response.ok) {
        if (response.status === 404) {
          mut_analyzerJsonesCache.push({ programId, json: undefined })
        }
        return undefined
      }
      const json: unknown = await response.json()
      mut_analyzerJsonesCache.push({ programId, json })
      return json
    },
  })
})()
