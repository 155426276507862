import type { ActionEvidence, ActionType } from '@0xtorch/core'
import type { ActionError } from '@pkg/basic'
import { Outlet, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_layoutPortfolio/history/')({
  validateSearch: (
    search: Record<string, unknown>,
  ): {
    hf_app?: string[]
    hf_app_op?: 'in-array' | 'not-in-array'
    hf_asset?: string[]
    hf_asset_op?: 'in-array' | 'not-in-array'
    hf_category?: string[]
    hf_category_op?: 'in-array' | 'not-in-array'
    hf_comment?: string
    hf_comment_op?: 'eq' | 'like'
    hf_cross?: string
    hf_cross_op?: 'eq' | 'ne'
    hf_error?: ActionError[]
    hf_error_op?: 'in-array' | 'not-in-array'
    hf_evidence?: ActionEvidence[]
    hf_evidence_op?: 'in-array' | 'not-in-array'
    hf_loan?: string
    hf_loan_op?: 'eq' | 'ne'
    hf_transfer?: string[]
    hf_transfer_op?:
      | 'in-array'
      | 'not-in-array'
      | 'in-array-from'
      | 'not-in-array-from'
      | 'in-array-to'
      | 'not-in-array-to'
    hf_type?: ActionType[]
    hf_type_op?: 'in-array' | 'not-in-array'
    hf_ts_from?: string
    hf_ts_to?: string
    hs_asc?: boolean
    p?: number
    s?: number
    vs?: string
    vo?: number
  } => {
    const p = Number.parseInt(search.p as string)
    const s = Number.parseInt(search.s as string)
    const vo = Number.parseInt(search.vo as string)
    return {
      hf_app: Array.isArray(search.hf_app)
        ? search.hf_app
        : typeof search.hf_app === 'string'
          ? [search.hf_app]
          : undefined,
      hf_app_op: ['in-array', 'not-in-array'].includes(
        search.hf_app_op as string,
      )
        ? (search.hf_app_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_asset: Array.isArray(search.hf_asset)
        ? search.hf_asset
        : typeof search.hf_asset === 'string'
          ? [search.hf_asset]
          : undefined,
      hf_asset_op: ['in-array', 'not-in-array'].includes(
        search.hf_asset_op as string,
      )
        ? (search.hf_asset_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_category: Array.isArray(search.hf_category)
        ? search.hf_category
        : typeof search.hf_category === 'string'
          ? [search.hf_category]
          : undefined,
      hf_category_op: ['in-array', 'not-in-array'].includes(
        search.hf_category_op as string,
      )
        ? (search.hf_category_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_comment:
        typeof search.hf_comment === 'string' ? search.hf_comment : undefined,
      hf_comment_op: ['eq', 'like'].includes(search.hf_comment_op as string)
        ? (search.hf_comment_op as 'eq' | 'like')
        : undefined,
      hf_cross:
        typeof search.hf_cross === 'string' ? search.hf_cross : undefined,
      hf_cross_op: ['eq', 'ne'].includes(search.hf_cross_op as string)
        ? (search.hf_cross_op as 'eq' | 'ne')
        : undefined,
      hf_error: Array.isArray(search.hf_error)
        ? search.hf_error
        : typeof search.hf_error === 'string'
          ? [search.hf_error]
          : undefined,
      hf_error_op: ['in-array', 'not-in-array'].includes(
        search.hf_error_op as string,
      )
        ? (search.hf_error_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_evidence: Array.isArray(search.hf_evidence)
        ? search.hf_evidence
        : typeof search.hf_evidence === 'string'
          ? [search.hf_evidence]
          : undefined,
      hf_evidence_op: ['in-array', 'not-in-array'].includes(
        search.hf_evidence_op as string,
      )
        ? (search.hf_evidence_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_loan: typeof search.hf_loan === 'string' ? search.hf_loan : undefined,
      hf_loan_op: ['eq', 'ne'].includes(search.hf_loan_op as string)
        ? (search.hf_loan_op as 'eq' | 'ne')
        : undefined,
      hf_transfer: Array.isArray(search.hf_transfer)
        ? search.hf_transfer
        : typeof search.hf_transfer === 'string'
          ? [search.hf_transfer]
          : undefined,
      hf_transfer_op: [
        'in-array',
        'not-in-array',
        'in-array-from',
        'not-in-array-from',
        'in-array-to',
        'not-in-array-to',
      ].includes(search.hf_transfer_op as string)
        ? (search.hf_transfer_op as
            | 'in-array'
            | 'not-in-array'
            | 'in-array-from'
            | 'not-in-array-from'
            | 'in-array-to'
            | 'not-in-array-to')
        : undefined,
      hf_type: Array.isArray(search.hf_type)
        ? search.hf_type
        : typeof search.hf_category === 'string'
          ? [search.hf_category]
          : undefined,
      hf_type_op: ['in-array', 'not-in-array'].includes(
        search.hf_type_op as string,
      )
        ? (search.hf_type_op as 'in-array' | 'not-in-array')
        : undefined,
      hf_ts_from: (search.hf_ts_from as string) ?? undefined,
      hf_ts_to: (search.hf_ts_to as string) ?? undefined,
      hs_asc:
        search.hs_asc === true ||
        (typeof search.hs_asc === 'string' && search.hs_asc === 'true')
          ? true
          : undefined,
      p: Number.isNaN(p) ? undefined : p,
      s: Number.isNaN(s) ? undefined : s,
      vs: (search.vs as string) ?? undefined,
      vo: Number.isNaN(vo) ? undefined : vo,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <Outlet />
}
