import { Outlet, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_layoutPortfolio/account')({
  validateSearch: (
    search: Record<string, unknown>,
  ): {
    account?: number
    sort?: string
    desc?: boolean
    f_name?: string
    f_name_op?: 'like' | 'not-like'
    f_category?: string[]
    f_category_op?: 'in-array' | 'not-in-array'
    p?: number
    s?: number
  } => {
    const account = Number.parseInt(search.account as string)
    const p = Number.parseInt(search.p as string)
    const s = Number.parseInt(search.s as string)
    return {
      account: Number.isNaN(account) ? undefined : account,
      sort: (search.sort as string) ?? undefined,
      desc:
        search.desc === true ||
        (typeof search.desc === 'string' && search.desc === 'true')
          ? true
          : undefined,
      f_name: typeof search.f_name === 'string' ? search.f_name : undefined,
      f_name_op: ['like', 'not-like'].includes(search.f_name_op as string)
        ? (search.f_name_op as 'like' | 'not-like')
        : undefined,
      f_category: Array.isArray(search.f_category)
        ? search.f_category
        : typeof search.f_category === 'string'
          ? [search.f_category]
          : undefined,
      f_category_op: ['in-array', 'not-in-array'].includes(
        search.f_category_op as string,
      )
        ? (search.f_category_op as 'in-array' | 'not-in-array')
        : undefined,
      p: Number.isNaN(p) ? undefined : p,
      s: Number.isNaN(s) ? undefined : s,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <Outlet />
}
