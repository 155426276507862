/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutPortfolioImport } from './routes/_layoutPortfolio'
import { Route as LayoutPortfolioIndexImport } from './routes/_layoutPortfolio/index'
import { Route as LayoutPortfolioAccountImport } from './routes/_layoutPortfolio/account'
import { Route as LayoutPortfolioHistoryIndexImport } from './routes/_layoutPortfolio/history/index'
import { Route as LayoutPortfolioDashboardIndexImport } from './routes/_layoutPortfolio/dashboard/index'

// Create Virtual Routes

const WaitlistLazyImport = createFileRoute('/waitlist')()
const SignInLazyImport = createFileRoute('/signIn')()
const OnboardingLazyImport = createFileRoute('/onboarding')()
const ForbidMultitabLazyImport = createFileRoute('/forbid-multitab')()
const SignInFactorOneLazyImport = createFileRoute('/signIn/factor-one')()
const LayoutPortfolioSettingIndexLazyImport = createFileRoute(
  '/_layoutPortfolio/setting/',
)()
const LayoutPortfolioSettingShareLazyImport = createFileRoute(
  '/_layoutPortfolio/setting/share',
)()
const LayoutPortfolioSettingPlanLazyImport = createFileRoute(
  '/_layoutPortfolio/setting/plan',
)()
const LayoutPortfolioSettingJournalLazyImport = createFileRoute(
  '/_layoutPortfolio/setting/journal',
)()
const LayoutPortfolioHistoryListLazyImport = createFileRoute(
  '/_layoutPortfolio/history/list',
)()
const LayoutPortfolioDashboardExportLazyImport = createFileRoute(
  '/_layoutPortfolio/dashboard/export',
)()

// Create/Update Routes

const WaitlistLazyRoute = WaitlistLazyImport.update({
  id: '/waitlist',
  path: '/waitlist',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/waitlist.lazy').then((d) => d.Route))

const SignInLazyRoute = SignInLazyImport.update({
  id: '/signIn',
  path: '/signIn',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/signIn.lazy').then((d) => d.Route))

const OnboardingLazyRoute = OnboardingLazyImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/onboarding.lazy').then((d) => d.Route))

const ForbidMultitabLazyRoute = ForbidMultitabLazyImport.update({
  id: '/forbid-multitab',
  path: '/forbid-multitab',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/forbid-multitab.lazy').then((d) => d.Route),
)

const LayoutPortfolioRoute = LayoutPortfolioImport.update({
  id: '/_layoutPortfolio',
  getParentRoute: () => rootRoute,
} as any)

const LayoutPortfolioIndexRoute = LayoutPortfolioIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutPortfolioRoute,
} as any)

const SignInFactorOneLazyRoute = SignInFactorOneLazyImport.update({
  id: '/factor-one',
  path: '/factor-one',
  getParentRoute: () => SignInLazyRoute,
} as any).lazy(() =>
  import('./routes/signIn/factor-one.lazy').then((d) => d.Route),
)

const LayoutPortfolioAccountRoute = LayoutPortfolioAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => LayoutPortfolioRoute,
} as any).lazy(() =>
  import('./routes/_layoutPortfolio/account.lazy').then((d) => d.Route),
)

const LayoutPortfolioSettingIndexLazyRoute =
  LayoutPortfolioSettingIndexLazyImport.update({
    id: '/setting/',
    path: '/setting/',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/setting/index.lazy').then((d) => d.Route),
  )

const LayoutPortfolioHistoryIndexRoute =
  LayoutPortfolioHistoryIndexImport.update({
    id: '/history/',
    path: '/history/',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/history/index.lazy').then((d) => d.Route),
  )

const LayoutPortfolioDashboardIndexRoute =
  LayoutPortfolioDashboardIndexImport.update({
    id: '/dashboard/',
    path: '/dashboard/',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/dashboard/index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPortfolioSettingShareLazyRoute =
  LayoutPortfolioSettingShareLazyImport.update({
    id: '/setting/share',
    path: '/setting/share',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/setting/share.lazy').then((d) => d.Route),
  )

const LayoutPortfolioSettingPlanLazyRoute =
  LayoutPortfolioSettingPlanLazyImport.update({
    id: '/setting/plan',
    path: '/setting/plan',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/setting/plan.lazy').then((d) => d.Route),
  )

const LayoutPortfolioSettingJournalLazyRoute =
  LayoutPortfolioSettingJournalLazyImport.update({
    id: '/setting/journal',
    path: '/setting/journal',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/setting/journal.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPortfolioHistoryListLazyRoute =
  LayoutPortfolioHistoryListLazyImport.update({
    id: '/history/list',
    path: '/history/list',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/history/list.lazy').then((d) => d.Route),
  )

const LayoutPortfolioDashboardExportLazyRoute =
  LayoutPortfolioDashboardExportLazyImport.update({
    id: '/dashboard/export',
    path: '/dashboard/export',
    getParentRoute: () => LayoutPortfolioRoute,
  } as any).lazy(() =>
    import('./routes/_layoutPortfolio/dashboard/export.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layoutPortfolio': {
      id: '/_layoutPortfolio'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutPortfolioImport
      parentRoute: typeof rootRoute
    }
    '/forbid-multitab': {
      id: '/forbid-multitab'
      path: '/forbid-multitab'
      fullPath: '/forbid-multitab'
      preLoaderRoute: typeof ForbidMultitabLazyImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingLazyImport
      parentRoute: typeof rootRoute
    }
    '/signIn': {
      id: '/signIn'
      path: '/signIn'
      fullPath: '/signIn'
      preLoaderRoute: typeof SignInLazyImport
      parentRoute: typeof rootRoute
    }
    '/waitlist': {
      id: '/waitlist'
      path: '/waitlist'
      fullPath: '/waitlist'
      preLoaderRoute: typeof WaitlistLazyImport
      parentRoute: typeof rootRoute
    }
    '/_layoutPortfolio/account': {
      id: '/_layoutPortfolio/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof LayoutPortfolioAccountImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/signIn/factor-one': {
      id: '/signIn/factor-one'
      path: '/factor-one'
      fullPath: '/signIn/factor-one'
      preLoaderRoute: typeof SignInFactorOneLazyImport
      parentRoute: typeof SignInLazyImport
    }
    '/_layoutPortfolio/': {
      id: '/_layoutPortfolio/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutPortfolioIndexImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/dashboard/export': {
      id: '/_layoutPortfolio/dashboard/export'
      path: '/dashboard/export'
      fullPath: '/dashboard/export'
      preLoaderRoute: typeof LayoutPortfolioDashboardExportLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/history/list': {
      id: '/_layoutPortfolio/history/list'
      path: '/history/list'
      fullPath: '/history/list'
      preLoaderRoute: typeof LayoutPortfolioHistoryListLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/setting/journal': {
      id: '/_layoutPortfolio/setting/journal'
      path: '/setting/journal'
      fullPath: '/setting/journal'
      preLoaderRoute: typeof LayoutPortfolioSettingJournalLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/setting/plan': {
      id: '/_layoutPortfolio/setting/plan'
      path: '/setting/plan'
      fullPath: '/setting/plan'
      preLoaderRoute: typeof LayoutPortfolioSettingPlanLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/setting/share': {
      id: '/_layoutPortfolio/setting/share'
      path: '/setting/share'
      fullPath: '/setting/share'
      preLoaderRoute: typeof LayoutPortfolioSettingShareLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/dashboard/': {
      id: '/_layoutPortfolio/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof LayoutPortfolioDashboardIndexImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/history/': {
      id: '/_layoutPortfolio/history/'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof LayoutPortfolioHistoryIndexImport
      parentRoute: typeof LayoutPortfolioImport
    }
    '/_layoutPortfolio/setting/': {
      id: '/_layoutPortfolio/setting/'
      path: '/setting'
      fullPath: '/setting'
      preLoaderRoute: typeof LayoutPortfolioSettingIndexLazyImport
      parentRoute: typeof LayoutPortfolioImport
    }
  }
}

// Create and export the route tree

interface LayoutPortfolioRouteChildren {
  LayoutPortfolioAccountRoute: typeof LayoutPortfolioAccountRoute
  LayoutPortfolioIndexRoute: typeof LayoutPortfolioIndexRoute
  LayoutPortfolioDashboardExportLazyRoute: typeof LayoutPortfolioDashboardExportLazyRoute
  LayoutPortfolioHistoryListLazyRoute: typeof LayoutPortfolioHistoryListLazyRoute
  LayoutPortfolioSettingJournalLazyRoute: typeof LayoutPortfolioSettingJournalLazyRoute
  LayoutPortfolioSettingPlanLazyRoute: typeof LayoutPortfolioSettingPlanLazyRoute
  LayoutPortfolioSettingShareLazyRoute: typeof LayoutPortfolioSettingShareLazyRoute
  LayoutPortfolioDashboardIndexRoute: typeof LayoutPortfolioDashboardIndexRoute
  LayoutPortfolioHistoryIndexRoute: typeof LayoutPortfolioHistoryIndexRoute
  LayoutPortfolioSettingIndexLazyRoute: typeof LayoutPortfolioSettingIndexLazyRoute
}

const LayoutPortfolioRouteChildren: LayoutPortfolioRouteChildren = {
  LayoutPortfolioAccountRoute: LayoutPortfolioAccountRoute,
  LayoutPortfolioIndexRoute: LayoutPortfolioIndexRoute,
  LayoutPortfolioDashboardExportLazyRoute:
    LayoutPortfolioDashboardExportLazyRoute,
  LayoutPortfolioHistoryListLazyRoute: LayoutPortfolioHistoryListLazyRoute,
  LayoutPortfolioSettingJournalLazyRoute:
    LayoutPortfolioSettingJournalLazyRoute,
  LayoutPortfolioSettingPlanLazyRoute: LayoutPortfolioSettingPlanLazyRoute,
  LayoutPortfolioSettingShareLazyRoute: LayoutPortfolioSettingShareLazyRoute,
  LayoutPortfolioDashboardIndexRoute: LayoutPortfolioDashboardIndexRoute,
  LayoutPortfolioHistoryIndexRoute: LayoutPortfolioHistoryIndexRoute,
  LayoutPortfolioSettingIndexLazyRoute: LayoutPortfolioSettingIndexLazyRoute,
}

const LayoutPortfolioRouteWithChildren = LayoutPortfolioRoute._addFileChildren(
  LayoutPortfolioRouteChildren,
)

interface SignInLazyRouteChildren {
  SignInFactorOneLazyRoute: typeof SignInFactorOneLazyRoute
}

const SignInLazyRouteChildren: SignInLazyRouteChildren = {
  SignInFactorOneLazyRoute: SignInFactorOneLazyRoute,
}

const SignInLazyRouteWithChildren = SignInLazyRoute._addFileChildren(
  SignInLazyRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof LayoutPortfolioRouteWithChildren
  '/forbid-multitab': typeof ForbidMultitabLazyRoute
  '/onboarding': typeof OnboardingLazyRoute
  '/signIn': typeof SignInLazyRouteWithChildren
  '/waitlist': typeof WaitlistLazyRoute
  '/account': typeof LayoutPortfolioAccountRoute
  '/signIn/factor-one': typeof SignInFactorOneLazyRoute
  '/': typeof LayoutPortfolioIndexRoute
  '/dashboard/export': typeof LayoutPortfolioDashboardExportLazyRoute
  '/history/list': typeof LayoutPortfolioHistoryListLazyRoute
  '/setting/journal': typeof LayoutPortfolioSettingJournalLazyRoute
  '/setting/plan': typeof LayoutPortfolioSettingPlanLazyRoute
  '/setting/share': typeof LayoutPortfolioSettingShareLazyRoute
  '/dashboard': typeof LayoutPortfolioDashboardIndexRoute
  '/history': typeof LayoutPortfolioHistoryIndexRoute
  '/setting': typeof LayoutPortfolioSettingIndexLazyRoute
}

export interface FileRoutesByTo {
  '/forbid-multitab': typeof ForbidMultitabLazyRoute
  '/onboarding': typeof OnboardingLazyRoute
  '/signIn': typeof SignInLazyRouteWithChildren
  '/waitlist': typeof WaitlistLazyRoute
  '/account': typeof LayoutPortfolioAccountRoute
  '/signIn/factor-one': typeof SignInFactorOneLazyRoute
  '/': typeof LayoutPortfolioIndexRoute
  '/dashboard/export': typeof LayoutPortfolioDashboardExportLazyRoute
  '/history/list': typeof LayoutPortfolioHistoryListLazyRoute
  '/setting/journal': typeof LayoutPortfolioSettingJournalLazyRoute
  '/setting/plan': typeof LayoutPortfolioSettingPlanLazyRoute
  '/setting/share': typeof LayoutPortfolioSettingShareLazyRoute
  '/dashboard': typeof LayoutPortfolioDashboardIndexRoute
  '/history': typeof LayoutPortfolioHistoryIndexRoute
  '/setting': typeof LayoutPortfolioSettingIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layoutPortfolio': typeof LayoutPortfolioRouteWithChildren
  '/forbid-multitab': typeof ForbidMultitabLazyRoute
  '/onboarding': typeof OnboardingLazyRoute
  '/signIn': typeof SignInLazyRouteWithChildren
  '/waitlist': typeof WaitlistLazyRoute
  '/_layoutPortfolio/account': typeof LayoutPortfolioAccountRoute
  '/signIn/factor-one': typeof SignInFactorOneLazyRoute
  '/_layoutPortfolio/': typeof LayoutPortfolioIndexRoute
  '/_layoutPortfolio/dashboard/export': typeof LayoutPortfolioDashboardExportLazyRoute
  '/_layoutPortfolio/history/list': typeof LayoutPortfolioHistoryListLazyRoute
  '/_layoutPortfolio/setting/journal': typeof LayoutPortfolioSettingJournalLazyRoute
  '/_layoutPortfolio/setting/plan': typeof LayoutPortfolioSettingPlanLazyRoute
  '/_layoutPortfolio/setting/share': typeof LayoutPortfolioSettingShareLazyRoute
  '/_layoutPortfolio/dashboard/': typeof LayoutPortfolioDashboardIndexRoute
  '/_layoutPortfolio/history/': typeof LayoutPortfolioHistoryIndexRoute
  '/_layoutPortfolio/setting/': typeof LayoutPortfolioSettingIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/forbid-multitab'
    | '/onboarding'
    | '/signIn'
    | '/waitlist'
    | '/account'
    | '/signIn/factor-one'
    | '/'
    | '/dashboard/export'
    | '/history/list'
    | '/setting/journal'
    | '/setting/plan'
    | '/setting/share'
    | '/dashboard'
    | '/history'
    | '/setting'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/forbid-multitab'
    | '/onboarding'
    | '/signIn'
    | '/waitlist'
    | '/account'
    | '/signIn/factor-one'
    | '/'
    | '/dashboard/export'
    | '/history/list'
    | '/setting/journal'
    | '/setting/plan'
    | '/setting/share'
    | '/dashboard'
    | '/history'
    | '/setting'
  id:
    | '__root__'
    | '/_layoutPortfolio'
    | '/forbid-multitab'
    | '/onboarding'
    | '/signIn'
    | '/waitlist'
    | '/_layoutPortfolio/account'
    | '/signIn/factor-one'
    | '/_layoutPortfolio/'
    | '/_layoutPortfolio/dashboard/export'
    | '/_layoutPortfolio/history/list'
    | '/_layoutPortfolio/setting/journal'
    | '/_layoutPortfolio/setting/plan'
    | '/_layoutPortfolio/setting/share'
    | '/_layoutPortfolio/dashboard/'
    | '/_layoutPortfolio/history/'
    | '/_layoutPortfolio/setting/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutPortfolioRoute: typeof LayoutPortfolioRouteWithChildren
  ForbidMultitabLazyRoute: typeof ForbidMultitabLazyRoute
  OnboardingLazyRoute: typeof OnboardingLazyRoute
  SignInLazyRoute: typeof SignInLazyRouteWithChildren
  WaitlistLazyRoute: typeof WaitlistLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  LayoutPortfolioRoute: LayoutPortfolioRouteWithChildren,
  ForbidMultitabLazyRoute: ForbidMultitabLazyRoute,
  OnboardingLazyRoute: OnboardingLazyRoute,
  SignInLazyRoute: SignInLazyRouteWithChildren,
  WaitlistLazyRoute: WaitlistLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layoutPortfolio",
        "/forbid-multitab",
        "/onboarding",
        "/signIn",
        "/waitlist"
      ]
    },
    "/_layoutPortfolio": {
      "filePath": "_layoutPortfolio.tsx",
      "children": [
        "/_layoutPortfolio/account",
        "/_layoutPortfolio/",
        "/_layoutPortfolio/dashboard/export",
        "/_layoutPortfolio/history/list",
        "/_layoutPortfolio/setting/journal",
        "/_layoutPortfolio/setting/plan",
        "/_layoutPortfolio/setting/share",
        "/_layoutPortfolio/dashboard/",
        "/_layoutPortfolio/history/",
        "/_layoutPortfolio/setting/"
      ]
    },
    "/forbid-multitab": {
      "filePath": "forbid-multitab.lazy.tsx"
    },
    "/onboarding": {
      "filePath": "onboarding.lazy.tsx"
    },
    "/signIn": {
      "filePath": "signIn.lazy.tsx",
      "children": [
        "/signIn/factor-one"
      ]
    },
    "/waitlist": {
      "filePath": "waitlist.lazy.tsx"
    },
    "/_layoutPortfolio/account": {
      "filePath": "_layoutPortfolio/account.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/signIn/factor-one": {
      "filePath": "signIn/factor-one.lazy.tsx",
      "parent": "/signIn"
    },
    "/_layoutPortfolio/": {
      "filePath": "_layoutPortfolio/index.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/dashboard/export": {
      "filePath": "_layoutPortfolio/dashboard/export.lazy.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/history/list": {
      "filePath": "_layoutPortfolio/history/list.lazy.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/setting/journal": {
      "filePath": "_layoutPortfolio/setting/journal.lazy.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/setting/plan": {
      "filePath": "_layoutPortfolio/setting/plan.lazy.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/setting/share": {
      "filePath": "_layoutPortfolio/setting/share.lazy.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/dashboard/": {
      "filePath": "_layoutPortfolio/dashboard/index.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/history/": {
      "filePath": "_layoutPortfolio/history/index.tsx",
      "parent": "/_layoutPortfolio"
    },
    "/_layoutPortfolio/setting/": {
      "filePath": "_layoutPortfolio/setting/index.lazy.tsx",
      "parent": "/_layoutPortfolio"
    }
  }
}
ROUTE_MANIFEST_END */
