import { syncTable } from '../../schema'
import type { DatabaseWithTransaction } from '../../types'
import { conflictUpdateAllExcept } from '../../utils'

export const upsertSyncTimestamp = async (params: {
  database: DatabaseWithTransaction
  key: string
  timestamp: number
}): Promise<void> => {
  const {
    database: { database },
    key,
    timestamp,
  } = params

  await database
    .insert(syncTable)
    .values({
      key,
      timestamp: new Date(timestamp),
    })
    .onConflictDoUpdate({
      target: [syncTable.key],
      set: conflictUpdateAllExcept(syncTable, ['key']),
    })
}
