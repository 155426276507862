import type { AssetBalance } from '@0xtorch/accounting'
import {
  type BigDecimal,
  createBigDecimal,
  plus,
  times,
} from '@0xtorch/big-decimal'
import type {
  CryptoCurrency,
  CryptoCurrencyDataSource,
  FiatCurrency,
} from '@0xtorch/core'
import type { AssetValue } from '@pkg/database-portfolio'
import type {
  AccountingPeriod,
  AssetBalances,
  AssetBorrows,
} from '@pkg/database-portfolio/src/types'

type GenerateAssetValuesParameters = {
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  accountingPeriod: AccountingPeriod
  assetBalances: readonly AssetBalances[]
  assetBorrows: readonly AssetBorrows[]
  cryptoCurrencies: readonly CryptoCurrency[]
  fiat: FiatCurrency
}

export const generateAssetValues = async ({
  cryptoCurrencyDataSource,
  accountingPeriod,
  assetBalances,
  assetBorrows,
  cryptoCurrencies,
  fiat,
}: GenerateAssetValuesParameters): Promise<readonly AssetValue[]> => {
  // asset 毎に amount, value をまとめる
  const cryptoIds = new Set<string>()
  const assetBalanceValues: (AssetBalance & { asset: string })[] = []
  const assetBorrowValues: (AssetBalance & { asset: string })[] = []
  for (const { asset, balances } of assetBalances) {
    const cryptoId = parseAssetToCryptoId(asset)
    if (cryptoId !== undefined && !cryptoIds.has(cryptoId)) {
      cryptoIds.add(cryptoId)
    }
    assetBalanceValues.push({
      asset,
      amount: balances.reduce(
        (sum, { amount }) => plus(sum, amount),
        createBigDecimal(0n),
      ),
      value: balances.reduce(
        (sum, { value }) => plus(sum, value),
        createBigDecimal(0n),
      ),
    })
  }
  for (const { asset, borrows } of assetBorrows) {
    const cryptoId = parseAssetToCryptoId(asset)
    if (cryptoId !== undefined && !cryptoIds.has(cryptoId)) {
      cryptoIds.add(cryptoId)
    }
    assetBorrowValues.push({
      asset,
      amount: borrows.reduce(
        (sum, { amount }) => plus(sum, amount),
        createBigDecimal(0n),
      ),
      value: borrows.reduce(
        (sum, { value }) => plus(sum, value),
        createBigDecimal(0n),
      ),
    })
  }

  // 期末価格を取得
  const cryptoPrices =
    accountingPeriod.end < Date.now()
      ? await getCryptoPrices(
          cryptoCurrencies.filter(({ id }) => cryptoIds.has(id)),
          fiat,
          cryptoCurrencyDataSource,
          accountingPeriod.end,
        )
      : new Map()

  // asset value 生成
  return [
    ...assetBalanceValues.map((data) =>
      generateAssetValue(data, accountingPeriod.id, cryptoPrices, 'own'),
    ),
    ...assetBorrowValues.map((data) =>
      generateAssetValue(data, accountingPeriod.id, cryptoPrices, 'borrow'),
    ),
  ]
}

const parseAssetToCryptoId = (asset: string): string | undefined => {
  if (!asset.startsWith('CryptoCurrency/')) {
    return undefined
  }
  return asset.split('CryptoCurrency/')[1]
}

const getCryptoPrices = async (
  cryptoCurrencies: readonly CryptoCurrency[],
  fiat: FiatCurrency,
  cryptoCurrencyDataSource: CryptoCurrencyDataSource,
  timestamp: number,
): Promise<Map<string, BigDecimal>> => {
  const cryptoHistoricalPrices = await Promise.all(
    cryptoCurrencies.map((currency) =>
      cryptoCurrencyDataSource.getHistoricalPrices({
        targetCurrency: currency,
        vsCurrency: fiat,
        timestampList: [timestamp],
      }),
    ),
  )
  const prices = new Map<string, BigDecimal>()
  for (const [index, historicalPrices] of cryptoHistoricalPrices.entries()) {
    const price = historicalPrices.get(timestamp)
    if (price !== undefined) {
      prices.set(cryptoCurrencies[index].id, price)
    }
  }
  return prices
}

const generateAssetValue = (
  data: AssetBalance & { asset: string },
  accountingPeriodId: number,
  cryptoPrices: Map<string, BigDecimal>,
  type: AssetValue['type'],
): AssetValue => {
  const { asset, amount, value } = data
  const cryptoId = parseAssetToCryptoId(asset)
  const price = cryptoId === undefined ? undefined : cryptoPrices.get(cryptoId)
  return {
    accountingPeriodId,
    asset,
    type,
    amount,
    cost: value,
    value: price === undefined ? value : times(amount, price, 6),
    isKnownPeriodEndPrice: price !== undefined,
  }
}
