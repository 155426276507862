import type { LowerHex } from '@0xtorch/evm'
import { blobToHex, hexToBlob } from '@pkg/basic'
import type { SQL } from 'drizzle-orm'
import { and, eq, inArray } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../constants'
import {
  accountExchangeSchema,
  accountPolkadotSchema,
  accountServiceSchema,
  accountSolanaSchema,
} from '../querySchema'
import { accountTable } from '../schema'
import type { Account, AccountEvm, DatabaseWithTransaction } from '../types'

type SelectAccountsParameters = {
  database: DatabaseWithTransaction
  idList?: readonly number[]
  category?: string
  evmChainId?: number
  evmAddresses?: readonly LowerHex[]
  polkadotChainId?: number
  polkadotAddresses?: readonly string[]
  solanaAddresses?: readonly string[]
}

export const selectAccounts = async ({
  database: { database },
  idList,
  category,
  evmChainId,
  evmAddresses,
  polkadotChainId,
  polkadotAddresses,
  solanaAddresses,
}: SelectAccountsParameters): Promise<readonly Account[]> => {
  if (
    (idList?.length ?? 0) + (evmAddresses?.length ?? 0) >
    sqliteMaxHostParameterCount
  ) {
    throw new Error('Too many parameters')
  }

  // create where
  const whereConditions: SQL<unknown>[] = []
  if (idList !== undefined && idList.length > 0) {
    whereConditions.push(inArray(accountTable.id, [...idList]))
  }
  if (category !== undefined) {
    whereConditions.push(eq(accountTable.category, category))
  }
  if (evmChainId !== undefined) {
    whereConditions.push(eq(accountTable.evmChainId, evmChainId))
  }
  if (evmAddresses !== undefined && evmAddresses.length > 0) {
    whereConditions.push(
      inArray(
        accountTable.evmAddress,
        evmAddresses.map((address) => hexToBlob(address)),
      ),
    )
  }
  if (polkadotChainId !== undefined) {
    whereConditions.push(eq(accountTable.polkadotChainId, polkadotChainId))
  }
  if (polkadotAddresses !== undefined && polkadotAddresses.length > 0) {
    whereConditions.push(
      inArray(accountTable.polkadotAddress, [...polkadotAddresses]),
    )
  }
  if (solanaAddresses !== undefined && solanaAddresses.length > 0) {
    whereConditions.push(
      inArray(accountTable.solanaAddress, [...solanaAddresses]),
    )
  }
  let where: SQL<unknown> | undefined
  if (whereConditions.length === 0) {
    where = undefined
  } else if (whereConditions.length === 1) {
    where = whereConditions[0]
  } else {
    where = and(...whereConditions)
  }

  // execute query
  const accounts = await database.select().from(accountTable).where(where)
  return accounts.map((account) => parseAccount(account))
}

const parseAccount = (account: typeof accountTable.$inferSelect): Account => {
  switch (account.type) {
    case 'evm': {
      if (account.evmChainId === null) {
        throw new Error('evmChainId is null')
      }
      if (account.evmAddress === null) {
        throw new Error('evmAddress is null')
      }
      return {
        category: account.category,
        id: account.id,
        name: account.name,
        group: account.group,
        type: 'evm',
        evmChainId: account.evmChainId,
        evmAddress: blobToHex(account.evmAddress),
        syncing: account.syncing ?? false,
        lastSyncedAt: account.lastSyncedAt?.getTime() ?? undefined,
        syncRegardlessOfUsage: account.syncRegardlessOfUsage ?? false,
        lastTransactionCount: account.evmLastTxCount ?? undefined,
        evmToBlock: account.evmToBlock ?? undefined,
      } satisfies AccountEvm
    }
    case 'exchange': {
      return accountExchangeSchema.parse(account)
    }
    case 'polkadot': {
      return accountPolkadotSchema.parse(account)
    }
    case 'service': {
      return accountServiceSchema.parse(account)
    }
    case 'solana': {
      return accountSolanaSchema.parse(account)
    }
  }
}
