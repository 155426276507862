import type { Chain } from '@0xtorch/polkadot'
import type { Setter } from 'jotai'
import { progressesAtom } from './progress'

const checkingPolkadotAccountIdSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const checkedPolkadotAccountIdSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const checkingPolkadotBlockNumberSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const fetchedPolkadotBlockNumberSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const analyzedPolkadotBlockNumberSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}

type UpdateProgressListParameters = {
  readonly chain: Chain
  readonly set: Setter
}

const updateProgressList = ({ chain, set }: UpdateProgressListParameters) => {
  const checkingAccountSize = checkingPolkadotAccountIdSetMap[chain.id]?.size
  const checkedAccountSize = checkedPolkadotAccountIdSetMap[chain.id]?.size
  const checkingBlockSize = checkingPolkadotBlockNumberSetMap[chain.id]?.size
  const fetchedBlockSize = fetchedPolkadotBlockNumberSetMap[chain.id]?.size
  const analyzedBlockSize = analyzedPolkadotBlockNumberSetMap[chain.id]?.size

  if (checkingAccountSize !== undefined && checkingAccountSize > 0) {
    const progress = ((checkedAccountSize ?? 0) / checkingAccountSize) * 100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === chain.network)
        ? current.map((item) =>
            item.category === chain.network
              ? {
                  ...item,
                  status: 'check-account',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: chain.network,
              status: 'check-account',
              progress,
            },
          ],
    )
  } else if (checkingBlockSize !== undefined && checkingBlockSize > 0) {
    const checkingSize = checkingBlockSize * 2
    const checkedSize = (fetchedBlockSize ?? 0) + (analyzedBlockSize ?? 0)
    const progress = (checkedSize / checkingSize) * 100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === chain.network)
        ? current.map((item) =>
            item.category === chain.network
              ? {
                  ...item,
                  status: 'analyze',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: chain.network,
              status: 'analyze',
              progress,
            },
          ],
    )
  } else {
    set(progressesAtom, (current) =>
      current.filter((item) => item.category !== chain.network),
    )
  }
}

type SaveStartPolkadotChainAnalyzeProgressStatusParameters = {
  readonly chain: Chain
  readonly accountIdSet: Set<number>
  readonly set: Setter
}

export const saveStartPolkadotChainAnalyzeProgressStatus = ({
  chain,
  accountIdSet,
  set,
}: SaveStartPolkadotChainAnalyzeProgressStatusParameters) => {
  // set checking account id set
  checkingPolkadotAccountIdSetMap[chain.id] = accountIdSet

  // set progress
  updateProgressList({ chain, set })
}

type SaveFetchedPolkadotChainAccountDataProgressStatusParameters = {
  readonly chain: Chain
  readonly accountId: number
  readonly set: Setter
}

export const saveFetchedPolkadotChainAccountDataProgressStatus = ({
  chain,
  accountId,
  set,
}: SaveFetchedPolkadotChainAccountDataProgressStatusParameters) => {
  // set checked account id set
  const checkedAccountIdSet = checkedPolkadotAccountIdSetMap[chain.id]
  if (checkedAccountIdSet === undefined) {
    checkedPolkadotAccountIdSetMap[chain.id] = new Set([accountId])
  } else {
    checkedAccountIdSet.add(accountId)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveStartAnalyzePolkadotBlocksProgressStatusParameters = {
  readonly chain: Chain
  readonly blockNumberSet: Set<number>
  readonly set: Setter
}

export const saveStartAnalyzePolkadotBlocksProgressStatus = ({
  chain,
  blockNumberSet,
  set,
}: SaveStartAnalyzePolkadotBlocksProgressStatusParameters) => {
  // reset account id set
  checkingPolkadotAccountIdSetMap[chain.id] = undefined
  checkedPolkadotAccountIdSetMap[chain.id] = undefined

  // set checking block number set
  checkingPolkadotBlockNumberSetMap[chain.id] = blockNumberSet

  // set progress
  updateProgressList({ chain, set })
}

type SaveFetchedPolkadotChainBlockProgressStatusParameters = {
  readonly chain: Chain
  readonly blockNumber: number
  readonly set: Setter
}

export const saveFetchedPolkadotChainBlockProgressStatus = ({
  chain,
  blockNumber,
  set,
}: SaveFetchedPolkadotChainBlockProgressStatusParameters) => {
  // set fetched block number set
  const fetchedBlockNumberSet = fetchedPolkadotBlockNumberSetMap[chain.id]
  if (fetchedBlockNumberSet === undefined) {
    fetchedPolkadotBlockNumberSetMap[chain.id] = new Set([blockNumber])
  } else {
    fetchedBlockNumberSet.add(blockNumber)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveAnalyzedPolkadotChainBlockProgressStatusParameters = {
  readonly chain: Chain
  readonly blockNumber: number
  readonly set: Setter
}

export const saveAnalyzedPolkadotChainBlockProgressStatus = ({
  chain,
  blockNumber,
  set,
}: SaveAnalyzedPolkadotChainBlockProgressStatusParameters) => {
  // set analyzed block number set
  const analyzedBlockNumberSet = analyzedPolkadotBlockNumberSetMap[chain.id]
  if (analyzedBlockNumberSet === undefined) {
    analyzedPolkadotBlockNumberSetMap[chain.id] = new Set([blockNumber])
  } else {
    analyzedBlockNumberSet.add(blockNumber)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveEndPolkadotChainAnalyzeProgressStatusParameters = {
  readonly chain: Chain
  readonly set: Setter
}

export const saveEndPolkadotChainAnalyzeProgressStatus = ({
  chain,
  set,
}: SaveEndPolkadotChainAnalyzeProgressStatusParameters) => {
  // reset
  checkingPolkadotAccountIdSetMap[chain.id] = undefined
  checkedPolkadotAccountIdSetMap[chain.id] = undefined
  checkingPolkadotBlockNumberSetMap[chain.id] = undefined
  fetchedPolkadotBlockNumberSetMap[chain.id] = undefined
  analyzedPolkadotBlockNumberSetMap[chain.id] = undefined

  // remove progress
  updateProgressList({ chain, set })
}
