import { evmChains } from '@/constants/evmChain'
import {
  datasourceApiEndpoint,
  evmTokenApiEndpoint,
  priceDataSourceApiEndpoint,
  splTokenApiEndpoint,
} from '@/environment'
import type { RunToDatabase } from '@/types'
import { type LowerHex, isErc721NftId, isErc1155NftId } from '@0xtorch/evm'
import { isSolanaNftId } from '@0xtorch/solana'
import { divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import {
  type AccountEvm,
  type InsertAccountEvmData,
  getCryptoCurrencyLastUpdatedAt,
  getSyncTimestamp,
  selectAccounts,
  selectNfts,
  updateEvmTxAnalyzedStatus,
  upsertAccountsEvm,
  upsertCryptoCurrencies,
  upsertNfts,
  upsertSyncTimestamp,
} from '@pkg/database-portfolio'
import type { AppType } from 'crypto-currency-worker'
import type { AppType as DatasourceAppType } from 'datasource-api'
import type { AppType as EvmAppType } from 'evm-token-worker'
import { hc } from 'hono/client'
import type { AppType as SolanaAppType } from 'solana-token'

export const syncDatabase = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  // Sync remote data to local DB
  await createNewEvmChainAccounts({ readFromDatabase, writeToDatabase })
  await syncCryptoCurrencies({ readFromDatabase, writeToDatabase })
  await syncNfts({ readFromDatabase, writeToDatabase })
  await syncEvmDatasource({ readFromDatabase, writeToDatabase })
}

const createNewEvmChainAccounts = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const accounts = await readFromDatabase((database) =>
    selectAccounts({
      database,
    }),
  )
  const evmAddresses = new Set<LowerHex>()
  const evmAccountMap = new Map<LowerHex, AccountEvm[]>()
  for (const account of accounts) {
    if (account.type === 'evm') {
      evmAddresses.add(account.evmAddress)
      evmAccountMap.set(account.evmAddress, [
        ...(evmAccountMap.get(account.evmAddress) ?? []),
        account,
      ])
    }
  }
  const newAccounts: InsertAccountEvmData[] = []
  for (const [address, evmAccounts] of evmAccountMap.entries()) {
    if (evmAccounts.length === 0) {
      continue
    }
    const account = evmAccounts[0]
    for (const chain of evmChains) {
      if (evmAccounts.some((account) => account.evmChainId === chain.id)) {
        continue
      }
      newAccounts.push({
        group: address,
        name: account.name,
        type: 'evm',
        category: account.category,
        evmChainId: chain.id,
        evmAddress: hexToBlob(address),
      })
    }
  }
  if (newAccounts.length === 0) {
    return
  }
  await writeToDatabase((database) =>
    upsertAccountsEvm({
      database,
      data: newAccounts,
    }),
  )
}

const syncCryptoCurrencies = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const key = 'crypto-currency'
  // 全 currency の最終更新時間を取得
  const synced = await readFromDatabase((database) =>
    getSyncTimestamp({ database, key }),
  )
  const lastUpdatedAt = await readFromDatabase((database) =>
    getCryptoCurrencyLastUpdatedAt({ database }),
  )
  const lastSynced = Math.max(synced, lastUpdatedAt)
  // 最終同期から 24 時間以上経過していない場合は更新しない
  const now = Date.now()
  if (now - lastSynced < 24 * 60 * 60 * 1000) {
    return
  }
  const client = hc<AppType>(priceDataSourceApiEndpoint)
  const response = await client.v1.list.$get({
    query: {
      after: (lastSynced + 1).toString(),
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`)
  }
  const cryptoCurrencies = await response.json()
  await writeToDatabase((database) =>
    upsertSyncTimestamp({
      database,
      key,
      timestamp: now - 60_000,
    }),
  )
  if (cryptoCurrencies.length === 0) {
    return
  }
  await writeToDatabase((database) =>
    upsertCryptoCurrencies({
      database,
      data: cryptoCurrencies.map((currency) => ({
        id: currency.id,
        name: currency.name,
        symbol: currency.symbol,
        icon: currency.icon ?? null,
        coingeckoId: currency.market?.coingeckoId ?? null,
        marketCapUsd: currency.market?.marketCapUsd ?? null,
        priceDatasourceId: currency.priceDatasourceId ?? null,
        updatedAt: currency.updatedAt,
      })),
    }),
  )
}

const syncNfts = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const key = 'nft'

  const lastSynced = await readFromDatabase((database) =>
    getSyncTimestamp({ database, key }),
  )
  // 最終同期から 24 時間以上経過していない場合は更新しない
  const now = Date.now()
  if (now - lastSynced < 24 * 60 * 60 * 1000) {
    return
  }

  // DB から updatedAt=0 の NFT 取得
  const zeroTimeNftList = await readFromDatabase((database) =>
    selectNfts({
      database,
      before: 0,
    }),
  )
  if (zeroTimeNftList.length === 0) {
    return
  }
  const nftIdList = zeroTimeNftList.map((nft) => nft.id)

  // EVM 系 NFT ID を抽出
  const evmNftIdList = nftIdList.filter(
    (id) => isErc721NftId(id) || isErc1155NftId(id),
  )

  // Solana 系 NFT ID を抽出
  const solanaNftIdList = nftIdList.filter((id) => isSolanaNftId(id))

  if (evmNftIdList.length === 0 && solanaNftIdList.length === 0) {
    return
  }

  // API から NFT データ取得
  const [evmNfts, solanaNfts] = await Promise.all([
    fetchEvmNonZeroTimeNfts(evmNftIdList),
    fetchSolanaNonZeroTimeNfts(solanaNftIdList),
  ])

  await writeToDatabase((database) =>
    upsertSyncTimestamp({
      database,
      key,
      timestamp: now - 60_000,
    }),
  )

  if (evmNfts.length === 0 && solanaNfts.length === 0) {
    return
  }

  await writeToDatabase((database) =>
    upsertNfts({
      database,
      data: [...evmNfts, ...solanaNfts],
    }),
  )
}

const fetchEvmNonZeroTimeNfts = async (idList: readonly string[]) => {
  if (idList.length === 0) {
    return []
  }

  const client = hc<EvmAppType>(evmTokenApiEndpoint)
  const idListChunkList = divideArrayIntoChunks(idList, 40)
  const results = await Promise.all(
    idListChunkList.map((idListChunk) =>
      (async () => {
        const response = await client.v1.nft.$get({
          query: {
            after: '1',
            id: [...idListChunk],
          },
        })
        if (!response.ok) {
          throw new Error(
            `Failed to fetch NFT data | ${response.status}: ${response.statusText}`,
          )
        }
        return await response.json()
      })(),
    ),
  )
  return results.flat()
}

const fetchSolanaNonZeroTimeNfts = async (idList: readonly string[]) => {
  if (idList.length === 0) {
    return []
  }

  const client = hc<SolanaAppType>(splTokenApiEndpoint)
  const idListChunkList = divideArrayIntoChunks(idList, 40)
  const results = await Promise.all(
    idListChunkList.map((idListChunk) =>
      (async () => {
        const response = await client.v1.nft.$get({
          query: {
            after: '1',
            id: [...idListChunk],
          },
        })
        if (!response.ok) {
          throw new Error(
            `Failed to fetch NFT data | ${response.status}: ${response.statusText}`,
          )
        }
        return await response.json()
      })(),
    ),
  )
  return results.flat()
}

const syncEvmDatasource = async (params: {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
}) => {
  const { readFromDatabase, writeToDatabase } = params
  const keyEvmAnalyzer = 'evm-analyzer'
  const keyEvmAddress = 'evm-address'

  // 2025-01-09 08:00:00
  const addressStartTs = 1_736_409_600_000
  const evmAddressLastSynced = Math.max(
    addressStartTs,
    await readFromDatabase((database) =>
      getSyncTimestamp({ database, key: keyEvmAddress }),
    ),
  )
  const evmAnalyzerLastSynced = await readFromDatabase((database) =>
    getSyncTimestamp({ database, key: keyEvmAnalyzer }),
  )
  const now = Date.now()
  // 最終同期から 24 時間以上経過していない場合は更新しない
  if (now - evmAddressLastSynced < 1000 * 60 * 60 * 24) {
    return
  }
  if (now - evmAnalyzerLastSynced < 1000 * 60 * 60 * 24) {
    return
  }

  const client = hc<DatasourceAppType>(datasourceApiEndpoint)
  const [addressResponse, analyzerResponse] = await Promise.all([
    client.v1.evm.update.address.$get({
      query: {
        timestamp: (evmAddressLastSynced + 1).toString(),
      },
    }),
    client.v1.evm.update.analyzer.$get({
      query: {
        timestamp: (evmAnalyzerLastSynced + 1).toString(),
      },
    }),
  ])
  if (!addressResponse.ok) {
    throw new Error(`${addressResponse.status} ${addressResponse.statusText}`)
  }
  if (!analyzerResponse.ok) {
    throw new Error(`${analyzerResponse.status} ${analyzerResponse.statusText}`)
  }

  const addresses = await addressResponse.json()
  const functionIds = await analyzerResponse.json()

  // 更新された address, analyzer に関連する tx hash を analyzed=false に更新
  await writeToDatabase((database) =>
    updateEvmTxAnalyzedStatus({ database, addresses, functionIds }),
  )
  await writeToDatabase((database) =>
    upsertSyncTimestamp({
      database,
      key: keyEvmAddress,
      timestamp: now - 60_000,
    }),
  )
  await writeToDatabase((database) =>
    upsertSyncTimestamp({
      database,
      key: keyEvmAnalyzer,
      timestamp: now - 60_000,
    }),
  )
}
