import { cn } from '@/lib/utils'
import type { Portfolio } from '@/types'
import {
  Link,
  type RegisteredRouter,
  type RoutePaths,
  useRouterState,
} from '@tanstack/react-router'
import type { TFunction } from 'i18next'
import {
  ChartSpline,
  ChevronRight,
  CircleEllipsis,
  History,
  NotebookPen,
  PencilOff,
  Settings,
  Wallet,
} from 'lucide-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import PortfolioSwitcher from './portfolioSwitcher'
import { Button } from './ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from './ui/collapsible'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarSeparator,
} from './ui/sidebar'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip'

type IconType = 'dashboard' | 'account' | 'history' | 'setting' | 'other'

type MenuData = {
  title: string
  icon: IconType
  path: Exclude<RoutePaths<RegisteredRouter['routeTree']>, ''>
  sub?: readonly Omit<MenuData, 'icon'>[]
}

const createMenuData = (t: TFunction): readonly MenuData[] => [
  {
    title: t('Portfolio'),
    icon: 'dashboard',
    path: '/dashboard',
    sub: [
      {
        title: t('Dashboard'),
        path: '/dashboard',
      },
      {
        title: t('Data Export'),
        path: '/dashboard/export',
      },
    ],
  },
  {
    title: t('Account'),
    icon: 'account',
    path: '/account',
  },
  {
    title: t('History'),
    icon: 'history',
    path: '/history',
    sub: [
      {
        title: t('sidebar/menu/history-summary'),
        path: '/history',
      },
      {
        title: t('sidebar/menu/history-list'),
        path: '/history/list',
      },
    ],
  },
  {
    title: t('Setting'),
    icon: 'setting',
    path: '/setting',
    sub: [
      {
        title: t('Basic Setting'),
        path: '/setting',
      },
      {
        title: t('Manage Member'),
        path: '/setting/share',
      },
      {
        title: t('Plan & Payment'),
        path: '/setting/plan',
      },
      {
        title: t('sidebar/menu/setting-journal'),
        path: '/setting/journal',
      },
    ],
  },
]

type Props = {
  portfolios: readonly Portfolio[]
  portfolio: Portfolio
  readonly: boolean
}

const PortfolioSiderbar = memo((props: Props) => {
  const { portfolio, portfolios, readonly } = props

  return (
    <Sidebar collapsible="icon">
      <Header portfolio={portfolio} portfolios={portfolios} />
      <Content />
      <SidebarSeparator />
      <Footer
        isOwner={portfolio.isOwner}
        plan={portfolio.plan}
        readonly={readonly}
      />
    </Sidebar>
  )
})

const Header = (props: {
  portfolio: Portfolio
  portfolios: readonly Portfolio[]
}) => {
  const { portfolio, portfolios } = props
  return (
    <SidebarHeader>
      <SidebarMenu>
        <SidebarMenuItem>
          <SidebarMenuButton size="lg" asChild>
            <PortfolioSwitcher portfolio={portfolio} portfolios={portfolios} />
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>
  )
}

const Content = () => {
  const { t } = useTranslation()
  const { location } = useRouterState()
  const data = createMenuData(t)

  return (
    <SidebarContent>
      <SidebarGroup>
        <SidebarMenu>
          {data.map((menu) =>
            menu.sub === undefined ? (
              <DefaultMenuItem
                key={menu.title}
                title={menu.title}
                icon={menu.icon}
                path={menu.path}
                isActive={location.pathname === menu.path}
              />
            ) : (
              <CollapsibleMenuItem
                key={menu.title}
                title={menu.title}
                icon={menu.icon}
                path={menu.path}
                sub={menu.sub}
                pathname={location.pathname}
              />
            ),
          )}
          <OtherMenuItem />
        </SidebarMenu>
      </SidebarGroup>
    </SidebarContent>
  )
}

const DefaultMenuItem = memo(
  (props: {
    title: string
    icon: IconType
    path: Exclude<RoutePaths<RegisteredRouter['routeTree']>, ''>
    isActive: boolean
  }) => {
    const { title, icon, path, isActive } = props

    return (
      <SidebarMenuItem key={title}>
        <SidebarMenuButton
          asChild
          className="data-[active=false]:text-muted-foreground data-[active=false]:hover:text-sidebar-foreground"
          isActive={isActive}
        >
          <Link
            to={path}
            search={(prev) => ({
              ...prev,
              account: undefined,
              p: undefined,
              vo: undefined,
              vs: undefined,
            })}
          >
            <MenuIcon icon={icon} />
            <span>{title}</span>
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    )
  },
)

const CollapsibleMenuItem = (props: {
  title: string
  icon: IconType
  path: Exclude<RoutePaths<RegisteredRouter['routeTree']>, ''>
  sub: readonly Omit<MenuData, 'icon'>[]
  pathname: string
}) => {
  const { title, icon, path, sub, pathname } = props

  return (
    <Collapsible key={title} asChild defaultOpen={pathname.startsWith(path)}>
      <SidebarMenuItem>
        <CollapsibleMenuItemTrigger
          title={title}
          icon={icon}
          path={path}
          isActive={pathname.startsWith(path)}
        />
        <CollapsibleContent>
          <SidebarMenuSub>
            {sub.map((sub) => (
              <CollapsibleMenuSubItem
                key={sub.title}
                title={sub.title}
                path={sub.path}
                isActive={pathname === sub.path}
              />
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  )
}

const CollapsibleMenuItemTrigger = memo(
  (props: {
    title: string
    icon: IconType
    path: Exclude<RoutePaths<RegisteredRouter['routeTree']>, ''>
    isActive: boolean
  }) => {
    const { title, icon, path, isActive } = props
    return (
      <CollapsibleTrigger asChild className="group">
        <SidebarMenuButton
          asChild
          className="data-[active=false]:text-muted-foreground data-[active=false]:hover:text-sidebar-foreground"
          isActive={isActive}
        >
          <Link
            to={path}
            search={(prev) => ({
              ...prev,
              account: undefined,
              p: undefined,
              vo: undefined,
              vs: undefined,
            })}
          >
            <MenuIcon icon={icon} />
            <span>{title}</span>
            <div className="flex-1" />
            <div className="text-sidebar-foreground transition-all duration-150 group-data-[state=open]:rotate-90">
              <ChevronRight className="h-4 w-4" />
              <span className="sr-only">Toggle</span>
            </div>
          </Link>
        </SidebarMenuButton>
      </CollapsibleTrigger>
    )
  },
)

const CollapsibleMenuSubItem = memo(
  (props: {
    title: string
    path: Exclude<RoutePaths<RegisteredRouter['routeTree']>, ''>
    isActive: boolean
  }) => {
    const { title, path, isActive } = props
    return (
      <SidebarMenuSubItem>
        <SidebarMenuSubButton
          asChild
          className="data-[active=false]:text-muted-foreground data-[active=true]:text-primary data-[active=false]:hover:text-sidebar-foreground"
          isActive={isActive}
        >
          <Link
            to={path}
            search={(prev) => ({
              ...prev,
              account: undefined,
              p: undefined,
              vo: undefined,
              vs: undefined,
            })}
          >
            <span>{title}</span>
          </Link>
        </SidebarMenuSubButton>
      </SidebarMenuSubItem>
    )
  },
)

const OtherMenuItem = memo(() => {
  const { t } = useTranslation()
  return (
    <Collapsible asChild className="group">
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton className="text-muted-foreground hover:text-sidebar-foreground">
            <CircleEllipsis />
            <span>{t('sidebar/menu/other')}</span>
            <div className="flex-1" />
            <div className="text-sidebar-foreground transition-all duration-150 group-data-[state=open]:rotate-90">
              <ChevronRight className="h-4 w-4" />
              <span className="sr-only">Toggle</span>
            </div>
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                asChild
                className="text-muted-foreground hover:text-sidebar-foreground"
              >
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfCTj2Biy4m5sJqxVxwpBt7UiHcLECommLPBpCaseHYQLyygQ/viewform?usp=dialog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t('sidebar/menu/contact')}</span>
                </a>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                asChild
                className="text-muted-foreground hover:text-sidebar-foreground"
              >
                <a
                  href="https://docs.google.com/document/d/1j3LfZxDd17U6CmLI34Avvt5qpi-M03NU10EXJANmMCs/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t('sidebar/menu/terms-of-service')}</span>
                </a>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                asChild
                className="text-muted-foreground hover:text-sidebar-foreground"
              >
                <a
                  href="https://docs.google.com/document/d/1KspnFP1v9tbOyyYQK-VF4nDnxqQt9MhLwJqmdQO1uDk/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t('sidebar/menu/privacy-policy')}</span>
                </a>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                asChild
                className="text-muted-foreground hover:text-sidebar-foreground"
              >
                <a
                  href="https://x.com/cryptorch_jp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t('sidebar/menu/x-official-account')}</span>
                </a>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                asChild
                className="text-muted-foreground hover:text-sidebar-foreground"
              >
                <a
                  href="https://docs.google.com/document/d/1MnTlaOL5c2rOdGK2crueQEMjDRB8D5-wigBugPCxwPU/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t('sidebar/menu/manual-list')}</span>
                </a>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  )
})

const MenuIcon = (props: {
  icon: IconType
}) => {
  const { icon } = props
  switch (icon) {
    case 'account': {
      return <Wallet />
    }
    case 'dashboard': {
      return <ChartSpline />
    }
    case 'history': {
      return <History />
    }
    case 'setting': {
      return <Settings />
    }
    case 'other': {
      return <CircleEllipsis />
    }
  }
}

const Footer = memo(
  (props: {
    isOwner: boolean
    plan: Portfolio['plan']
    readonly: boolean
  }) => {
    const { plan, isOwner, readonly } = props
    const { t } = useTranslation()

    return (
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton className="h-auto cursor-auto divide-x hover:bg-sidebar active:bg-sidebar data-[active=true]:bg-sidebar data-[state=open]:hover:bg-sidebar">
              <PortfolioModeTooltip readonly={readonly} />
              <div className="ml-1 flex flex-1 flex-col pl-3">
                <div className="flex items-center justify-between px-2">
                  <span className="text-muted-foreground text-sm">
                    {t('Current Plan')}
                  </span>
                  <span className="font-bold text-sm">{plan}</span>
                </div>
                {isOwner && (
                  <Button variant="outline" size="sm" className="mt-3" asChild>
                    <Link
                      to="/setting/plan"
                      search={(prev) => ({
                        ...prev,
                        account: undefined,
                        p: undefined,
                        vo: undefined,
                        vs: undefined,
                      })}
                    >
                      {t('Upgrade Plan')}
                    </Link>
                  </Button>
                )}
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    )
  },
)

const PortfolioModeTooltip = (props: {
  readonly: boolean
}) => {
  const { readonly } = props
  const { t } = useTranslation()

  const commonClassName =
    'p-1 w-6 h-6 border rounded-full flex items-center justify-center'
  const variantClassName = readonly
    ? 'text-yellow-500 border-yellow-500'
    : 'text-green-500 border-green-500'
  const icon = readonly ? <PencilOff /> : <NotebookPen />
  const tooltipText = readonly ? t('Readonly Mode') : t('Writable Mode')

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(commonClassName, variantClassName)}>{icon}</div>
        </TooltipTrigger>
        <TooltipContent className="bg-secondary text-secondary-foreground">
          <p>{tooltipText}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default PortfolioSiderbar
