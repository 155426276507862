import {
  type Action,
  type CryptoCurrency,
  type CryptoCurrencyDataSource,
  type FiatCurrency,
  setActionPrices,
} from '@0xtorch/core'
import {
  type AnalyzedSolanaTransaction,
  createSolanaAddressId,
} from '@0xtorch/solana'
import { divideArrayIntoChunks } from '@pkg/basic'
import {
  type AccountSolana,
  getSolanaIndexes,
  ignoreLockedSolanaTxs,
  saveSolanaAccountIndexes,
  saveSolanaTxAndActions,
} from '@pkg/database-portfolio'
import type { AssetFetchClient } from '@pkg/datasource-client'
import { createSplTokenDatasource } from '@pkg/spl-token-datasource'
import { createSolanaDatasourceCache } from './createSolanaDatasourceCache'
import { fetchAndSaveActionNftList } from './fetchAndSaveActionNftList'
import type {
  AnalyzeSolanaTransactionParameters,
  FetchSolanaAddressDataParameters,
  FetchSolanaAddressDataReturnTypes,
  FetchSolanaTransactionsParameters,
  FetchSolanaTransactionsReturnTypes,
  RunToDatabase,
} from './types'

let mut_analyzingSolanaAccountList: AccountSolana[] = []
let mut_waitingSolanaAccountList: AccountSolana[] = []

type AnalyzeSolanaAccountsParameters = {
  readFromDatabase: RunToDatabase
  writeToDatabase: RunToDatabase
  getToken?: () => Promise<string | null>
  startTime: number
  accountList: readonly AccountSolana[]
  allSolanaAccountAddresses: readonly string[]
  cryptoCurrencyList: readonly CryptoCurrency[]
  fiatCurrency: FiatCurrency
  cryptoCurrencyDataSource: CryptoCurrencyDataSource
  assetFetchClient: AssetFetchClient
  rpcEndpoints: readonly string[]
  evmTokenApiEndpoint: string
  splTokenApiEndpoint: string
  fetchAddressData: (
    parameters: FetchSolanaAddressDataParameters,
  ) => Promise<FetchSolanaAddressDataReturnTypes>
  fetchTransactions: (
    parameters: FetchSolanaTransactionsParameters,
  ) => Promise<FetchSolanaTransactionsReturnTypes>
  analyzeTransaction: (
    parameters: AnalyzeSolanaTransactionParameters,
  ) => Promise<AnalyzedSolanaTransaction>
  onStart: (accountIdSet: Set<number>) => void
  onEnd: () => void
  onFetchedAccount: (accountId: number) => void
  onStartAnalyzeTransaction: (transactionSignatureSet: Set<string>) => void
  onFetchedTransaction: (signature: string) => void
  onAnalyzedTransaction: (signature: string) => void
}

export const analyzeSolanaAccounts = async ({
  readFromDatabase,
  writeToDatabase,
  getToken,
  startTime,
  accountList,
  allSolanaAccountAddresses,
  cryptoCurrencyList,
  fiatCurrency,
  cryptoCurrencyDataSource,
  assetFetchClient,
  rpcEndpoints,
  evmTokenApiEndpoint,
  splTokenApiEndpoint,
  fetchAddressData,
  fetchTransactions,
  analyzeTransaction,
  onStart,
  onEnd,
  onFetchedAccount,
  onStartAnalyzeTransaction,
  onFetchedTransaction,
  onAnalyzedTransaction,
}: AnalyzeSolanaAccountsParameters): Promise<void> => {
  // 解析中 solana account に含まれる account は除外する
  const filteredAccountList = accountList.filter(({ id }) =>
    mut_analyzingSolanaAccountList.every((account) => account.id !== id),
  )

  // 解析中 evm account が存在する場合、残った account を解析待ち evm account に入れて終了
  if (mut_analyzingSolanaAccountList.length > 0) {
    for (const account of filteredAccountList) {
      if (mut_waitingSolanaAccountList.every(({ id }) => id !== account.id)) {
        mut_waitingSolanaAccountList.push(account)
      }
    }
    return
  }

  // 解析中 evm account に入れて解析開始
  mut_analyzingSolanaAccountList.push(...filteredAccountList)
  const accountIdSet = new Set(filteredAccountList.map(({ id }) => id))
  onStart(accountIdSet)

  try {
    // account 毎に　signatures, tokenAccounts data 取得
    for (const account of filteredAccountList) {
      const token =
        getToken === undefined ? undefined : (await getToken()) ?? undefined

      const addressData = await fetchAddressData({
        rpcEndpoints,
        headers:
          token === undefined
            ? undefined
            : {
                Authorization: `Bearer ${token}`,
              },
        account,
        startTime,
      })

      if (addressData !== undefined) {
        // 取得した data を DB に保存
        // start time 以前の signature は除外
        // tokenAccounts は既存の内、取得した data に含まれないものは削除
        // account.lastSyncedAt も更新
        await writeToDatabase((database) =>
          saveSolanaAccountIndexes({
            database,
            accountId: account.id,
            signatures: addressData.signatures.filter(
              ({ timestamp }) => timestamp >= startTime,
            ),
            tokenAccountAddresses: addressData.tokenAccountAddresses,
          }),
        )
      }

      // account data 取得完了 callback
      onFetchedAccount(account.id)
    }

    // analyzed = false な signatures , token account owner map を DB から取得
    const { signatures, tokenAccountOwnerMap } = await readFromDatabase(
      (database) => getSolanaIndexes({ database, analyzed: false }),
    )

    // signature が空の場合は終了
    if (signatures.length === 0) {
      return
    }

    // 該当 tx を source とする isLocked=true の action が存在する tx は除外する
    // 除外した tx について、 accountSolanaSignatureTable.analyzed を true に更新
    const ignoreSignatureSet = await writeToDatabase((database) =>
      ignoreLockedSolanaTxs({ database, signatures }),
    )
    const targetSignatures = signatures.filter(
      (signature) => !ignoreSignatureSet.has(signature),
    )

    // transaction 解析開始 callback
    onStartAnalyzeTransaction(new Set(targetSignatures))

    // Create datasources
    const splTokenDatasource = createSplTokenDatasource({
      apiEndpoint: splTokenApiEndpoint,
      cryptoCurrencies: cryptoCurrencyList,
    })
    // TODO JSON data source は一旦ダミーを渡す
    const jsonDatasource = {
      get: () => Promise.resolve([]),
    }
    const accountIds = allSolanaAccountAddresses.map((address) =>
      createSolanaAddressId({ address }),
    )

    // filter をかけた target signatures を 100件毎に分割して100件毎処理
    const chunkSize = 100
    for (const signatureChunk of divideArrayIntoChunks(
      targetSignatures,
      chunkSize,
    )) {
      // transaction detail 一括取得
      const token =
        getToken === undefined ? undefined : (await getToken()) ?? undefined

      const { transactions } = await fetchTransactions({
        rpcEndpoints,
        signatures: signatureChunk,
        headers:
          token === undefined
            ? undefined
            : {
                Authorization: `Bearer ${token}`,
              },
        onFetched: onFetchedTransaction,
      })

      // Create solana datasource cache
      await createSolanaDatasourceCache({
        transactionList: transactions,
        cryptoCurrencyList,
        assetFetchClient,
        splTokenApiEndpoint,
      })

      const actions: Action[] = []
      const errorSignatures = new Set<string>()
      // tx 1件ずつ解析
      for (const transaction of transactions) {
        try {
          const analyzed = await analyzeTransaction({
            transaction,
            tokenAccountOwnerMap,
            splTokenDatasource,
            jsonDatasource,
            accountIds,
          })
          actions.push(...analyzed.actions)
        } catch (error) {
          // TODO error toast を表示するようにする
          console.error(
            'Failed to analyze solana transaction:',
            transaction.transaction.signatures[0],
          )
          console.error(error)
          errorSignatures.add(transaction.transaction.signatures[0])
        }
        // transaction 解析完了 callback
        onAnalyzedTransaction(transaction.transaction.signatures[0])
      }

      // 生成した action の NFT data を API から取得してDB保存
      await writeToDatabase((database) =>
        fetchAndSaveActionNftList({
          database,
          evmTokenApiEndpoint,
          splTokenApiEndpoint,
          actionList: actions,
        }),
      )

      // 生成した action の価格設定
      const pricedActions = await setActionPrices({
        actions,
        fiat: fiatCurrency,
        dataSource: cryptoCurrencyDataSource,
      })

      // - rule に合わせて action 更新
      // - DB に action 保存 & 関連データ更新
      // portfolio.isTxGenerated を false に更新
      // accountSolanaSignatureTable.analyzed を true に更新
      // decoded transaction を actionSource に保存
      // action を保存・ rule があれば rule に合わせて更新
      await writeToDatabase((database) =>
        saveSolanaTxAndActions({
          database,
          transactions: transactions.filter(
            ({ transaction }) =>
              !errorSignatures.has(transaction.signatures[0]),
          ),
          actions: pricedActions,
        }),
      )
    }
  } finally {
    // 処理完了時に解析中 evm account を空にする
    mut_analyzingSolanaAccountList = []

    // 処理完了を callback
    onEnd()

    // 解析待ち solana account が存在する場合は account list を解析待ち solana account に変えて再度呼び出す
    // 解析待ち solana account は空にする
    if (mut_waitingSolanaAccountList.length > 0) {
      const newAccountList = [...mut_waitingSolanaAccountList]
      mut_waitingSolanaAccountList = []
      await analyzeSolanaAccounts({
        readFromDatabase,
        writeToDatabase,
        getToken,
        startTime,
        accountList: newAccountList,
        allSolanaAccountAddresses: [
          ...new Set([
            ...allSolanaAccountAddresses,
            ...newAccountList.map(({ solanaAddress }) => solanaAddress),
          ]),
        ],
        cryptoCurrencyList,
        fiatCurrency,
        cryptoCurrencyDataSource,
        assetFetchClient,
        rpcEndpoints,
        evmTokenApiEndpoint,
        splTokenApiEndpoint,
        fetchAddressData,
        fetchTransactions,
        analyzeTransaction,
        onStart,
        onEnd,
        onFetchedAccount,
        onStartAnalyzeTransaction,
        onFetchedTransaction,
        onAnalyzedTransaction,
      })
    }
  }
}
