import type { RunToDatabase } from '@/types'
import { selectPortfolio } from '@pkg/database-portfolio'
import { atom } from 'jotai'

export const isRequiredCalculationAtom = atom(false)

export const isCalculatingAtom = atom(false)

export const updateIsRequiredCalculationAtom = atom(
  undefined,
  async (
    _,
    set,
    parameters: {
      readFromDatabase: RunToDatabase
    },
  ) => {
    const portfolio = await parameters.readFromDatabase((database) =>
      selectPortfolio({ database }),
    )
    if (portfolio === undefined) {
      set(isRequiredCalculationAtom, false)
      return
    }
    set(isRequiredCalculationAtom, portfolio.isTxGenerated === false)
  },
)
