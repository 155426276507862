import type { Action } from '@0xtorch/core'
import {
  type LowerHex,
  type TransactionDecoded,
  createEvmActionSource,
  createEvmAddressId,
} from '@0xtorch/evm'
import { blobToHex, divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  accountErc20TokenRelationColumnCount,
  actionAccountRelationColumnCount,
  actionColumnCount,
  actionSourceColumnCount,
  actionTransferColumnCount,
  cryptoCurrencyColumnCount,
  erc20TokenColumnCount,
  nftColumnCount,
  sqliteMaxCompoundSelectCount,
  sqliteMaxHostParameterCount,
} from '../constants'
import { actionsToInsertSqls } from '../createSql/actionsToInsertSqls'
import {
  accountErc20TokenRelationTable,
  accountEvmTxIndexRelationTable,
  accountTable,
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionAccountRelationTable,
  actionSourceTable,
  actionTable,
  actionTransferTable,
  cryptoCurrencyTable,
  erc20TokenTable,
  nftTable,
  portfolioTable,
} from '../schema'
import type { DatabaseWithTransaction } from '../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../utils'

type SaveEvmTxAndActionsParameters = {
  readonly database: DatabaseWithTransaction
  readonly chainId: number
  readonly transactions: readonly TransactionDecoded[]
  readonly actions: readonly Action[]
}

export const saveEvmTxAndActions = async ({
  database: { database, transaction },
  chainId,
  transactions,
  actions,
}: SaveEvmTxAndActionsParameters) => {
  const hashes = new Set(transactions.map((tx) => tx.hash))
  const sources = [...hashes].map((hash) =>
    createEvmActionSource({ chainId, transactionHash: hash }),
  )
  const accountRows = await database
    .select({ id: accountTable.id, address: accountTable.evmAddress })
    .from(accountTable)
    .where(eq(accountTable.evmChainId, chainId))
  const accounts: { id: number; address: LowerHex }[] = []
  for (const row of accountRows) {
    if (row.address === null) {
      continue
    }
    accounts.push({ id: row.id, address: blobToHex(row.address) })
  }

  const { erc20Tokens, accountErc20Relations } = generateErc20Data({
    chainId,
    transactions,
    accounts,
  })
  const actionAccountRelations = generateActionAccountRelations({
    chainId,
    transactions,
    actions,
    accounts,
  })

  const { actionSqls, actionTransferSqls, cryptoSqls, nftSqls } =
    actionsToInsertSqls(actions)

  await transaction([
    (tx) => [
      // portfolio.isTxGenerated を false に更新
      tx
        .update(portfolioTable)
        .set({ isTxGenerated: false }),
      // accountEvmIndexRelation.analyzed を true に更新
      ...divideArrayIntoChunks(
        [...hashes],
        sqliteMaxHostParameterCount - 2,
      ).map((chunk) =>
        tx
          .update(accountEvmTxIndexRelationTable)
          .set({ analyzed: true })
          .where(
            and(
              inArray(
                accountEvmTxIndexRelationTable.accountId,
                tx
                  .select({ id: accountTable.id })
                  .from(accountTable)
                  .where(eq(accountTable.evmChainId, chainId)),
              ),
              inArray(
                accountEvmTxIndexRelationTable.hash,
                chunk.map((hash) => hexToBlob(hash)),
              ),
            ),
          ),
      ),
      // decoded transaction を actionSource に保存 (upsert)
      ...divideArrayIntoChunks(
        transactions,
        getMaxInsertRowCount(actionSourceColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(actionSourceTable)
          .values(
            chunk.map((tx) => ({
              id: createEvmActionSource({
                chainId: tx.chainId,
                transactionHash: tx.hash,
              }),
              type: 'evm' as const,
              evmChainId: tx.chainId,
              evmHash: hexToBlob(tx.hash),
              evmFrom: hexToBlob(tx.from.address),
              evmTo: tx.to === undefined ? null : hexToBlob(tx.to.address),
              evmStatus: tx.status,
              evmFunctionId:
                tx.input.length >= 10
                  ? hexToBlob(`0x${tx.input.slice(2, 10)}`)
                  : undefined,
              evmFunctionName: tx.function?.name,
            })),
          )
          .onConflictDoUpdate({
            target: [actionSourceTable.id],
            set: conflictUpdateAllExcept(actionSourceTable, ['id']),
          }),
      ),
      // sources に関連する transaction transfer を削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTransferTable).where(
            inArray(
              accountingTransactionTransferTable.transactionId,
              tx
                .select({ id: accountingTransactionTable.id })
                .from(accountingTransactionTable)
                .leftJoin(
                  actionTable,
                  eq(accountingTransactionTable.actionId, actionTable.id),
                )
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // sources に関連する action account relation を削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionAccountRelationTable).where(
            inArray(
              actionAccountRelationTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // account.syncing を analyzed=false の accountEvmIndexRelation の存在に合わせて更新
      ...accounts.map(({ id }) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountEvmTxIndexRelationTable} WHERE ${accountEvmTxIndexRelationTable.accountId} = ${id} AND ${accountEvmTxIndexRelationTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
      // sources に関連する transaction を削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTable).where(
            inArray(
              accountingTransactionTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // sources に関連する action transfer を削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionTransferTable).where(
            inArray(
              actionTransferTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // sources に関連する action を削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx
            .delete(actionTable)
            .where(inArray(actionTable.sourceId, [...chunk])),
      ),
    ],
    (tx) => [
      // crypto
      ...divideArrayIntoChunks(
        cryptoSqls,
        getMaxInsertRowCount(cryptoCurrencyColumnCount),
      ).map((chunk) =>
        tx
          .insert(cryptoCurrencyTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // nft
      ...divideArrayIntoChunks(
        nftSqls,
        getMaxInsertRowCount(nftColumnCount),
      ).map((chunk) =>
        tx
          .insert(nftTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // action
      ...divideArrayIntoChunks(
        actionSqls,
        // TODO 一時的に SQLITE_MAX_COMPOUND_SELECT を意識して数を調整
        Math.min(
          getMaxInsertRowCount(actionColumnCount + 3),
          Math.floor(sqliteMaxCompoundSelectCount / 3),
        ),
      ).map((chunk) => tx.insert(actionTable).values([...chunk])),
      // action transfer
      ...divideArrayIntoChunks(
        actionTransferSqls,
        getMaxInsertRowCount(actionTransferColumnCount),
      ).map((chunk) => tx.insert(actionTransferTable).values([...chunk])),
      // action account relation
      ...divideArrayIntoChunks(
        actionAccountRelations,
        getMaxInsertRowCount(actionAccountRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(actionAccountRelationTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // erc20 token
      ...divideArrayIntoChunks(
        erc20Tokens,
        getMaxInsertRowCount(erc20TokenColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(erc20TokenTable)
          .values([...chunk])
          .onConflictDoUpdate({
            target: [erc20TokenTable.id],
            set: conflictUpdateAllExcept(erc20TokenTable, ['id']),
          }),
      ),
      // account erc20 relation
      ...divideArrayIntoChunks(
        accountErc20Relations,
        getMaxInsertRowCount(accountErc20TokenRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(accountErc20TokenRelationTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
    ],
  ])
}

// transaction と action から actionAccountRelation data を作成
// action transfer の from, to に account address が含まれていれば account に関連づける
// transaction from, to に account address が含まれていれば account に関連づける
const generateActionAccountRelations = (params: {
  chainId: number
  transactions: readonly TransactionDecoded[]
  actions: readonly Action[]
  accounts: readonly { id: number; address: LowerHex }[]
}): readonly { actionId: string; accountId: number }[] => {
  const { chainId, transactions, actions, accounts } = params

  const addressMap = new Map<LowerHex, number>(
    accounts.map(({ address, id }) => [address, id]),
  )
  const evmAddressIdMap = new Map<string, number>(
    accounts.map(({ address, id }) => [
      createEvmAddressId({ chainId, address }),
      id,
    ]),
  )
  const sourceTransactionFromMap = new Map<string, LowerHex>(
    transactions.map((tx) => [
      createEvmActionSource({ chainId, transactionHash: tx.hash }),
      tx.from.address,
    ]),
  )

  const relationMap = new Map<string, { actionId: string; accountId: number }>()
  for (const action of actions) {
    const actionId = `${action.source}_${action.order}`
    // transaction from と一致する account との relation data 作成
    const from = sourceTransactionFromMap.get(action.source)
    const txFromAccountId =
      from === undefined ? undefined : addressMap.get(from)
    if (txFromAccountId !== undefined) {
      const id = `${actionId}/${txFromAccountId}`
      if (!relationMap.has(id)) {
        relationMap.set(actionId, { actionId, accountId: txFromAccountId })
      }
    }

    // action transfer の from, to と一致する account を追加
    for (const transfer of action.transfers) {
      const transferFromAccountId =
        transfer.from === undefined
          ? undefined
          : evmAddressIdMap.get(transfer.from)
      if (transferFromAccountId !== undefined) {
        const id = `${actionId}/${transferFromAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferFromAccountId,
          })
        }
      }
      const transferToAccountId =
        transfer.to === undefined ? undefined : evmAddressIdMap.get(transfer.to)
      if (transferToAccountId !== undefined) {
        const id = `${actionId}/${transferToAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferToAccountId,
          })
        }
      }
    }
  }

  return [...relationMap.values()]
}

const generateErc20Data = (params: {
  chainId: number
  transactions: readonly TransactionDecoded[]
  accounts: readonly { id: number; address: LowerHex }[]
}): {
  erc20Tokens: readonly SQLiteInsertValue<typeof erc20TokenTable>[]
  accountErc20Relations: readonly SQLiteInsertValue<
    typeof accountErc20TokenRelationTable
  >[]
} => {
  const { chainId, transactions, accounts } = params

  const addressMap = new Map<LowerHex, number>(
    accounts.map(({ address, id }) => [address, id]),
  )
  const erc20TokenSqlMap = new Map<
    string,
    SQLiteInsertValue<typeof erc20TokenTable>
  >()
  const accountErc20RelationMap = new Map<
    string,
    SQLiteInsertValue<typeof accountErc20TokenRelationTable>
  >()

  for (const transaction of transactions) {
    for (const transfer of transaction.erc20Transfers) {
      const fromAccountId = addressMap.get(transfer.from)
      const toAccountId = addressMap.get(transfer.to)
      if (fromAccountId === undefined && toAccountId === undefined) {
        continue
      }
      const tokenId = createEvmAddressId({ chainId, address: transfer.address })
      if (!erc20TokenSqlMap.has(tokenId)) {
        erc20TokenSqlMap.set(tokenId, {
          id: tokenId,
          chainId,
          address: hexToBlob(transfer.address),
          name: transfer.token.name,
          symbol: transfer.token.symbol,
          decimals: transfer.token.decimals,
          currencyId: transfer.token.currency.id,
        })
      }
      if (fromAccountId !== undefined) {
        const id = `${tokenId}/${fromAccountId}`
        if (!accountErc20RelationMap.has(id)) {
          accountErc20RelationMap.set(id, {
            tokenId,
            accountId: fromAccountId,
          })
        }
      }
      if (toAccountId !== undefined) {
        const id = `${tokenId}/${toAccountId}`
        if (!accountErc20RelationMap.has(id)) {
          accountErc20RelationMap.set(id, {
            tokenId,
            accountId: toAccountId,
          })
        }
      }
    }
  }

  return {
    erc20Tokens: [...erc20TokenSqlMap.values()],
    accountErc20Relations: [...accountErc20RelationMap.values()],
  }
}
