import { apiEndpoint } from '@/environment'
import {
  getPortfolioUpdatedAtFromLocalStorage,
  setPortfolioToLocalStorage,
} from '@/localStorage'
import type { Portfolio } from '@/types'
import { compress } from '@/utils'
import { getDatabaseFile } from '@/utils/database'

export const syncDbFileToRemote = async (params: {
  portfolio: Portfolio
  token: string
}) => {
  const { portfolio, token } = params

  const localUpdatedAt = getPortfolioUpdatedAtFromLocalStorage() ?? 0
  if (localUpdatedAt <= portfolio.updatedAt) {
    return
  }

  const file = await getDatabaseFile(portfolio.uuid)
  // 圧縮 (gzip)
  const gzipFile = await compress(file)
  const body = new FormData()
  body.append('file', gzipFile)
  const url = new URL(
    `/v1/portfolio/${portfolio.uuid}/db?timestamp=${localUpdatedAt}`,
    apiEndpoint,
  )
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
  if (!response.ok) {
    throw new Error(
      `Failed to upload portfolio DB file: ${response.status} ${response.statusText}`,
    )
  }
  setPortfolioToLocalStorage({
    ...portfolio,
    updatedAt: localUpdatedAt,
  })
}
