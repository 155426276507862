import type { Setter } from 'jotai'
import { progressesAtom } from './progress'

const checkingSolanaAccountIdSet = new Set<number>()
const checkedSolanaAccountIdSet = new Set<number>()
const checkingSolanaTransactionSignatureSet = new Set<string>()
const fetchedSolanaTransactionSignatureSet = new Set<string>()
const analyzedSolanaTransactionSignatureSet = new Set<string>()

type UpdateProgressListParameters = {
  readonly set: Setter
}

const updateProgressList = ({ set }: UpdateProgressListParameters) => {
  const checkingAccountSize = checkingSolanaAccountIdSet.size
  const checkedAccountSize = checkedSolanaAccountIdSet.size
  const checkingTransactionSize = checkingSolanaTransactionSignatureSet.size
  const fetchedTransactionSize = fetchedSolanaTransactionSignatureSet.size
  const analyzedTransactionSize = analyzedSolanaTransactionSignatureSet.size

  if (checkingAccountSize > 0) {
    const progress = (checkedAccountSize / checkingAccountSize) * 100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === 'solana')
        ? current.map((item) =>
            item.category === 'solana'
              ? {
                  ...item,
                  status: 'check-account',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: 'solana',
              status: 'check-account',
              progress,
            },
          ],
    )
  } else if (checkingTransactionSize > 0) {
    const progress =
      ((fetchedTransactionSize + analyzedTransactionSize) /
        (checkingTransactionSize * 2)) *
      100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === 'solana')
        ? current.map((item) =>
            item.category === 'solana'
              ? {
                  ...item,
                  status: 'analyze',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: 'solana',
              status: 'analyze',
              progress,
            },
          ],
    )
  } else {
    set(progressesAtom, (current) =>
      current.filter((item) => item.category !== 'solana'),
    )
  }
}

type SaveStartSolanaAnalyzeProgressStatusParameters = {
  readonly accountIdSet: Set<number>
  readonly set: Setter
}

export const saveStartSolanaAnalyzeProgressStatus = ({
  accountIdSet,
  set,
}: SaveStartSolanaAnalyzeProgressStatusParameters) => {
  // set checking account id set
  checkingSolanaAccountIdSet.clear()
  for (const accountId of accountIdSet) {
    checkingSolanaAccountIdSet.add(accountId)
  }

  // set progress
  updateProgressList({ set })
}

type SaveFetchedSolanaAccountDataProgressStatusParameters = {
  readonly accountId: number
  readonly set: Setter
}

export const saveFetchedSolanaAccountDataProgressStatus = ({
  accountId,
  set,
}: SaveFetchedSolanaAccountDataProgressStatusParameters) => {
  // set checked account id set
  checkedSolanaAccountIdSet.add(accountId)

  // set progress
  updateProgressList({ set })
}

type SaveStartAnalyzeSolanaTransactionListProgressStatusParameters = {
  readonly transactionSignatureSet: Set<string>
  readonly set: Setter
}

export const saveStartAnalyzeSolanaTransactionListProgressStatus = ({
  transactionSignatureSet,
  set,
}: SaveStartAnalyzeSolanaTransactionListProgressStatusParameters) => {
  // reset account id set
  checkingSolanaAccountIdSet.clear()
  checkedSolanaAccountIdSet.clear()

  // set checking transaction hash set
  checkingSolanaTransactionSignatureSet.clear()
  for (const transactionSignature of transactionSignatureSet) {
    checkingSolanaTransactionSignatureSet.add(transactionSignature)
  }

  // set progress
  updateProgressList({ set })
}

type SaveFetchedSolanaTransactionProgressStatusParameters = {
  readonly transactionSignature: string
  readonly set: Setter
}

export const saveFetchedSolanaTransactionProgressStatus = ({
  transactionSignature,
  set,
}: SaveFetchedSolanaTransactionProgressStatusParameters) => {
  // set fetched transaction hash set
  fetchedSolanaTransactionSignatureSet.add(transactionSignature)

  // set progress
  updateProgressList({ set })
}

type SaveAnalyzedSolanaTransactionProgressStatusParameters = {
  readonly transactionSignature: string
  readonly set: Setter
}

export const saveAnalyzedSolanaTransactionProgressStatus = ({
  transactionSignature,
  set,
}: SaveAnalyzedSolanaTransactionProgressStatusParameters) => {
  // set analyzed transaction hash set
  analyzedSolanaTransactionSignatureSet.add(transactionSignature)

  // set progress
  updateProgressList({ set })
}

type SaveEndSolanaAnalyzeProgressStatusParameters = {
  readonly set: Setter
}

export const saveEndSolanaAnalyzeProgressStatus = ({
  set,
}: SaveEndSolanaAnalyzeProgressStatusParameters) => {
  // reset
  checkingSolanaAccountIdSet.clear()
  checkedSolanaAccountIdSet.clear()
  checkingSolanaTransactionSignatureSet.clear()
  fetchedSolanaTransactionSignatureSet.clear()
  analyzedSolanaTransactionSignatureSet.clear()

  // remove progress
  updateProgressList({ set })
}
