import LoadingOverlay from '@/components/loadingOverlay'
import { Toaster } from '@/components/ui/toaster'
import {
  forbidUsingDatabase,
  getIsForbiddenUsingDatabase,
} from '@/utils/database'
import { useAuth } from '@clerk/clerk-react'
import {
  Outlet,
  createRootRoute,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const channel = new BroadcastChannel('tab')
channel.postMessage('open')

export const Route = createRootRoute({
  validateSearch: (search: Record<string, unknown>): { id?: string } => {
    return {
      id: (search.id as string) ?? undefined,
    }
  },
  component: () => {
    const { userId, isLoaded } = useAuth()
    const routerState = useRouterState()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
      const listener = (event: MessageEvent) => {
        if (event.data === 'open') {
          forbidUsingDatabase()
          navigate({
            to: '/forbid-multitab',
            search: () => ({}),
          })
        }
      }

      channel.addEventListener('message', listener)

      return () => {
        channel.removeEventListener('message', listener)
      }
    }, [navigate])

    useEffect(() => {
      if (!isLoaded) {
        return
      }
      if (
        getIsForbiddenUsingDatabase() &&
        routerState.location.pathname !== '/forbid-multitab'
      ) {
        navigate({
          to: '/forbid-multitab',
        })
      }
      if (
        !getIsForbiddenUsingDatabase() &&
        routerState.location.pathname === '/forbid-multitab'
      ) {
        navigate({
          to: '/',
        })
      }

      if (
        userId === null &&
        routerState.location.pathname !== '/signIn' &&
        routerState.location.pathname !== '/signIn/factor-one' &&
        routerState.location.pathname !== '/waitlist'
      ) {
        navigate({
          to: '/signIn',
        })
      }
      if (
        userId !== null &&
        (routerState.location.pathname === '/signIn' ||
          routerState.location.pathname === '/signIn/factor-one' ||
          routerState.location.pathname === '/waitlist')
      ) {
        navigate({
          to: '/',
        })
      }
    }, [isLoaded, userId, routerState, navigate])

    if (!isLoaded) {
      return <LoadingOverlay description={t('loading/authenticating')} />
    }

    return (
      <>
        <Outlet />
        {/* <TanStackRouterDevtools position="top-left" /> */}
        <Toaster />
      </>
    )
  },
})
