export type AssetFetchClient = {
  get: (path: string, timeout?: number) => Promise<Response>
}

type CreateAssetFetchClientParameters = {
  endpoint: string
}

class Semaphore {
  private permits: number
  private queue: Array<() => void> = []

  constructor(permits: number) {
    this.permits = permits
  }

  async acquire(): Promise<void> {
    if (this.permits > 0) {
      this.permits -= 1
      return Promise.resolve()
    }

    return new Promise<void>((resolve) => {
      this.queue.push(() => {
        this.permits -= 1
        resolve()
      })
    })
  }

  release(): void {
    this.permits += 1
    const next = this.queue.shift()
    if (next !== undefined) {
      next()
    }
  }
}

export const createAssetFetchClient = ({
  endpoint,
}: CreateAssetFetchClientParameters): AssetFetchClient => {
  const semaphore = new Semaphore(100)

  const get = async (path: string, timeout = 300_000): Promise<Response> => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeout)

    try {
      await Promise.race([
        semaphore.acquire(),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error('Timeout while waiting for slot')),
            timeout,
          ),
        ),
      ])

      try {
        const response = await fetch(`${endpoint}${path}`, {
          signal: controller.signal,
        })

        if (!response.ok && response.status !== 404) {
          throw new Error(`${response.status}: ${response.statusText}`)
        }

        return response
      } catch (error) {
        if (error instanceof Error && error.message.includes('429')) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          return await get(path, timeout)
        }
        throw error
      }
    } finally {
      clearTimeout(timeoutId)
      semaphore.release()
    }
  }

  return {
    get,
  }
}
