import { toStringBigDecimal } from '@0xtorch/big-decimal'
import type {
  Action as ActionCore,
  CryptoCurrency,
  Nft,
  Transfer,
} from '@0xtorch/core'
import { type Action, cryptoCurrencyConverter } from '@pkg/basic'
import { sql } from 'drizzle-orm'
import type { SQLiteInsertValue } from 'drizzle-orm/sqlite-core'
import {
  type actionTable,
  type actionTransferTable,
  type cryptoCurrencyTable,
  type nftTable,
  singleActionRuleTable,
} from '../schema'

type ActionsToInsertSqlsReturnTypes = {
  actionSqls: readonly SQLiteInsertValue<typeof actionTable>[]
  actionTransferSqls: readonly SQLiteInsertValue<typeof actionTransferTable>[]
  cryptoSqls: readonly SQLiteInsertValue<typeof cryptoCurrencyTable>[]
  nftSqls: readonly SQLiteInsertValue<typeof nftTable>[]
}

export const actionsToInsertSqls = (
  actions: readonly (Action | ActionCore)[],
): ActionsToInsertSqlsReturnTypes => {
  const actionSqls: SQLiteInsertValue<typeof actionTable>[] = []
  const actionTransferSqls: SQLiteInsertValue<typeof actionTransferTable>[] = []
  const cryptoSqls = new Map<
    string,
    SQLiteInsertValue<typeof cryptoCurrencyTable>
  >()
  const nftSqls = new Map<string, SQLiteInsertValue<typeof nftTable>>()

  for (const action of actions) {
    const actionId = `${action.source}_${action.order}`
    actionSqls.push(createActionSql(action))

    // target (nft)
    if (action.target !== undefined && !nftSqls.has(action.target.id)) {
      nftSqls.set(action.target.id, createNftSql(action.target))
    }

    for (const [order, transfer] of action.transfers.entries()) {
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        cryptoCurrencyConverter.has(transfer.asset.id)
      ) {
        transfer.asset =
          cryptoCurrencyConverter.get(transfer.asset.id) ?? transfer.asset
      }
      actionTransferSqls.push(
        createActionTransferSql(transfer, actionId, order),
      )

      // asset (crypto / nft)
      if (
        transfer.asset.type === 'CryptoCurrency' &&
        !cryptoSqls.has(transfer.asset.id)
      ) {
        cryptoSqls.set(
          transfer.asset.id,
          createCryptoCurrencySql(transfer.asset),
        )
      }
      if (transfer.asset.type === 'Nft' && !nftSqls.has(transfer.asset.id)) {
        nftSqls.set(transfer.asset.id, createNftSql(transfer.asset))
      }
    }
  }

  return {
    actionSqls,
    actionTransferSqls,
    cryptoSqls: [...cryptoSqls.values()],
    nftSqls: [...nftSqls.values()],
  }
}

const createActionSql = (
  action: Action | ActionCore,
): SQLiteInsertValue<typeof actionTable> => ({
  id: `${action.source}_${action.order}`,
  sourceId: action.source,
  order: action.order,
  type:
    action.evidence === 'none'
      ? sql`COALESCE(
(
select
${singleActionRuleTable.newType}
from
${singleActionRuleTable}
where
(
${singleActionRuleTable.source} = ${action.source}
and ${singleActionRuleTable.targetType} = ${action.type}
)
), ${action.type}
)
`
      : action.type,
  evidence:
    action.evidence === 'none'
      ? sql`(
SELECT
CASE WHEN EXISTS (
SELECT
1
FROM
${singleActionRuleTable}
WHERE
${singleActionRuleTable.source} = ${action.source}
AND ${singleActionRuleTable.targetType} = ${action.type}
) THEN 'user-rule' ELSE 'none' END
)`
      : action.evidence,
  timestamp: new Date(action.timestamp),
  comment: action.comment,
  app: action.app,
  loanId: action.loanId,
  crossId: action.crossId,
  crossType: action.crossType,
  targetId: action.target?.id,
  locked: 'locked' in action ? action.locked : false,
  generatedTx: false,
  relatedAssetIds: action.transfers
    .map((transfer) => transfer.asset.id)
    .join(','),
  fromAddresses: action.transfers
    .map((transfer) => transfer.from)
    .filter((from) => from !== undefined)
    .join(','),
  toAddresses: action.transfers
    .map((transfer) => transfer.to)
    .filter((to) => to !== undefined)
    .join(','),
})

const createActionTransferSql = (
  transfer: Transfer,
  actionId: string,
  order: number,
): SQLiteInsertValue<typeof actionTransferTable> => ({
  actionId,
  order,
  direction: transfer.direction,
  from: transfer.from,
  to: transfer.to,
  cryptoId:
    transfer.asset.type === 'CryptoCurrency' ? transfer.asset.id : undefined,
  fiatId:
    transfer.asset.type === 'FiatCurrency' ? transfer.asset.id : undefined,
  nftId: transfer.asset.type === 'Nft' ? transfer.asset.id : undefined,
  amount: toStringBigDecimal(transfer.amount),
  price:
    transfer.price === undefined
      ? undefined
      : toStringBigDecimal(transfer.price),
})

const createCryptoCurrencySql = (
  asset: CryptoCurrency,
): SQLiteInsertValue<typeof cryptoCurrencyTable> => ({
  id: asset.id,
  name: asset.name,
  symbol: asset.symbol,
  icon: asset.icon,
  coingeckoId: asset.market?.coingeckoId,
  marketCapUsd:
    asset.market?.marketCapUsd === undefined
      ? undefined
      : Math.floor(asset.market.marketCapUsd),
  priceDatasourceId: asset.priceDatasourceId,
  updatedAt: asset.updatedAt,
})

const createNftSql = (asset: Nft): SQLiteInsertValue<typeof nftTable> => ({
  id: asset.id,
  name: asset.name,
  image: asset.image,
  metadata: asset.metadata,
  updatedAt: asset.updatedAt,
})
