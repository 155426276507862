import type { Chain, Hex } from '@0xtorch/evm'
import type { Setter } from 'jotai'
import { progressesAtom } from './progress'

const mut_checkingEvmAccountIdSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const mut_checkedEvmAccountIdSetMap: {
  [chainId: number]: Set<number> | undefined
} = {}
const mut_checkingEvmTransactionHashSetMap: {
  [chainId: number]: Set<Hex> | undefined
} = {}
const mut_fetchedEvmTransactionHashSetMap: {
  [chainId: number]: Set<Hex> | undefined
} = {}
const mut_analyzedEvmTransactionHashSetMap: {
  [chainId: number]: Set<Hex> | undefined
} = {}

type UpdateProgressListParameters = {
  readonly chain: Chain
  readonly set: Setter
}

const updateProgressList = ({ chain, set }: UpdateProgressListParameters) => {
  const checkingAccountSize = mut_checkingEvmAccountIdSetMap[chain.id]?.size
  const checkedAccountSize = mut_checkedEvmAccountIdSetMap[chain.id]?.size
  const checkingTransactionSize =
    mut_checkingEvmTransactionHashSetMap[chain.id]?.size
  const fetchedTransactionSize =
    mut_fetchedEvmTransactionHashSetMap[chain.id]?.size
  const analyzedTransactionSize =
    mut_analyzedEvmTransactionHashSetMap[chain.id]?.size

  if (checkingAccountSize !== undefined && checkingAccountSize > 0) {
    const progress = ((checkedAccountSize ?? 0) / checkingAccountSize) * 100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === chain.network)
        ? current.map((item) =>
            item.category === chain.network
              ? {
                  ...item,
                  status: 'check-account',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: chain.network,
              status: 'check-account',
              progress,
            },
          ],
    )
  } else if (
    checkingTransactionSize !== undefined &&
    checkingTransactionSize > 0
  ) {
    const checkingSize = checkingTransactionSize * 2
    const checkedSize =
      (fetchedTransactionSize ?? 0) + (analyzedTransactionSize ?? 0)
    const progress = (checkedSize / checkingSize) * 100
    set(progressesAtom, (current) =>
      current.some((item) => item.category === chain.network)
        ? current.map((item) =>
            item.category === chain.network
              ? {
                  ...item,
                  status: 'analyze',
                  progress,
                }
              : item,
          )
        : [
            ...current,
            {
              category: chain.network,
              status: 'analyze',
              progress,
            },
          ],
    )
  } else {
    set(progressesAtom, (current) =>
      current.filter((item) => item.category !== chain.network),
    )
  }
}

type SaveStartEvmChainAnalyzeProgressStatusParameters = {
  readonly chain: Chain
  readonly accountIdSet: Set<number>
  readonly set: Setter
}

export const saveStartEvmChainAnalyzeProgressStatus = ({
  chain,
  accountIdSet,
  set,
}: SaveStartEvmChainAnalyzeProgressStatusParameters) => {
  // set checking account id set
  mut_checkingEvmAccountIdSetMap[chain.id] = accountIdSet

  // set progress
  updateProgressList({ chain, set })
}

type SaveFetchedEvmChainAccountDataProgressStatusParameters = {
  readonly chain: Chain
  readonly accountId: number
  readonly set: Setter
}

export const saveFetchedEvmChainAccountDataProgressStatus = ({
  chain,
  accountId,
  set,
}: SaveFetchedEvmChainAccountDataProgressStatusParameters) => {
  // set checked account id set
  const checkedAccountIdSet = mut_checkedEvmAccountIdSetMap[chain.id]
  if (checkedAccountIdSet === undefined) {
    mut_checkedEvmAccountIdSetMap[chain.id] = new Set([accountId])
  } else {
    checkedAccountIdSet.add(accountId)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveStartAnalyzeEvmTransactionListProgressStatusParameters = {
  readonly chain: Chain
  readonly transactionHashSet: Set<Hex>
  readonly set: Setter
}

export const saveStartAnalyzeEvmTransactionListProgressStatus = ({
  chain,
  transactionHashSet,
  set,
}: SaveStartAnalyzeEvmTransactionListProgressStatusParameters) => {
  // reset account id set
  mut_checkingEvmAccountIdSetMap[chain.id] = undefined
  mut_checkedEvmAccountIdSetMap[chain.id] = undefined

  // set checking transaction hash set
  mut_checkingEvmTransactionHashSetMap[chain.id] = transactionHashSet

  // set progress
  updateProgressList({ chain, set })
}

type SaveFetchedEvmChainTransactionProgressStatusParameters = {
  readonly chain: Chain
  readonly transactionHash: Hex
  readonly set: Setter
}

export const saveFetchedEvmChainTransactionProgressStatus = ({
  chain,
  transactionHash,
  set,
}: SaveFetchedEvmChainTransactionProgressStatusParameters) => {
  // set fetched transaction hash set
  const fetchedTransactionHashSet =
    mut_fetchedEvmTransactionHashSetMap[chain.id]
  if (fetchedTransactionHashSet === undefined) {
    mut_fetchedEvmTransactionHashSetMap[chain.id] = new Set([transactionHash])
  } else {
    fetchedTransactionHashSet.add(transactionHash)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveAnalyzedEvmChainTransactionProgressStatusParameters = {
  readonly chain: Chain
  readonly transactionHash: Hex
  readonly set: Setter
}

export const saveAnalyzedEvmChainTransactionProgressStatus = ({
  chain,
  transactionHash,
  set,
}: SaveAnalyzedEvmChainTransactionProgressStatusParameters) => {
  // set analyzed transaction hash set
  const analyzedTransactionHashSet =
    mut_analyzedEvmTransactionHashSetMap[chain.id]
  if (analyzedTransactionHashSet === undefined) {
    mut_analyzedEvmTransactionHashSetMap[chain.id] = new Set([transactionHash])
  } else {
    analyzedTransactionHashSet.add(transactionHash)
  }

  // set progress
  updateProgressList({ chain, set })
}

type SaveEndEvmChainAnalyzeProgressStatusParameters = {
  readonly chain: Chain
  readonly set: Setter
}

export const saveEndEvmChainAnalyzeProgressStatus = ({
  chain,
  set,
}: SaveEndEvmChainAnalyzeProgressStatusParameters) => {
  // reset
  mut_checkingEvmAccountIdSetMap[chain.id] = undefined
  mut_checkedEvmAccountIdSetMap[chain.id] = undefined
  mut_checkingEvmTransactionHashSetMap[chain.id] = undefined
  mut_fetchedEvmTransactionHashSetMap[chain.id] = undefined
  mut_analyzedEvmTransactionHashSetMap[chain.id] = undefined

  // remove progress
  updateProgressList({ chain, set })
}
