import type { ActionFilter } from '@pkg/basic'
import { blankApiKeyEvmChainList, blankApiKeyPolkadotChains } from '@pkg/basic'
import type { SQL } from 'drizzle-orm'
import {
  eq,
  getTableColumns,
  gte,
  inArray,
  isNull,
  like,
  lte,
  ne,
  notInArray,
  notLike,
  or,
  sql,
} from 'drizzle-orm'
import type { SQLiteTable } from 'drizzle-orm/sqlite-core'
import { sqliteMaxHostParameterCount } from './constants'
import { actionTable } from './schema'

export const conflictUpdateAllExcept = <
  T extends SQLiteTable,
  E extends (keyof T['$inferInsert'])[],
>(
  table: T,
  except: E,
) => {
  const columns = getTableColumns(table)
  const updateColumns = Object.entries(columns).filter(
    ([col]) => !except.includes(col as keyof typeof table.$inferInsert),
  )

  return Object.fromEntries(
    updateColumns.map(([colName, table]) => [
      colName,
      sql.raw(`excluded."${table.name}"`),
    ]),
  ) as Omit<Record<keyof typeof table.$inferInsert, SQL>, E[number]>
}

export const getMaxInsertRowCount = (columnCount: number, isUpsert = false) =>
  Math.floor(sqliteMaxHostParameterCount / columnCount / (isUpsert ? 2 : 1))

export const createWhereConditionsByActionFilterList = (
  filterList: ActionFilter[],
): SQL<unknown>[] => {
  const mut_whereConditions: SQL<unknown>[] = []
  for (const filter of filterList) {
    switch (filter.type) {
      case 'account': {
        if (filter.accountIdList.length === 1) {
          if (filter.inArray) {
            mut_whereConditions.push(
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              or(
                like(actionTable.fromAddresses, `%${filter.accountIdList[0]}%`),
                like(actionTable.toAddresses, `%${filter.accountIdList[0]}%`),
              )!,
            )
          } else {
            mut_whereConditions.push(
              notLike(
                actionTable.fromAddresses,
                `%${filter.accountIdList[0]}%`,
              ),
              notLike(actionTable.toAddresses, `%${filter.accountIdList[0]}%`),
            )
          }
        } else if (filter.accountIdList.length > 1) {
          if (filter.inArray) {
            mut_whereConditions.push(
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              or(
                ...filter.accountIdList.flatMap((accountId) => [
                  like(actionTable.fromAddresses, `%${accountId}%`),
                  like(actionTable.toAddresses, `%${accountId}%`),
                ]),
              )!,
            )
          } else {
            mut_whereConditions.push(
              ...filter.accountIdList.flatMap((accountId) => [
                notLike(actionTable.fromAddresses, `%${accountId}%`),
                notLike(actionTable.toAddresses, `%${accountId}%`),
              ]),
            )
          }
        }
        break
      }
      case 'action': {
        if (filter.actionList.length === 1) {
          mut_whereConditions.push(
            filter.inArray
              ? eq(actionTable.type, filter.actionList[0])
              : ne(actionTable.type, filter.actionList[0]),
          )
        } else if (filter.actionList.length > 1) {
          mut_whereConditions.push(
            filter.inArray
              ? inArray(actionTable.type, [...filter.actionList])
              : notInArray(actionTable.type, [...filter.actionList]),
          )
        }
        break
      }
      case 'app': {
        if (filter.appIdList.length === 1) {
          mut_whereConditions.push(
            filter.inArray
              ? eq(actionTable.app, filter.appIdList[0])
              : // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  ne(actionTable.app, filter.appIdList[0]),
                  isNull(actionTable.app),
                )!,
          )
        } else if (filter.appIdList.length > 1) {
          mut_whereConditions.push(
            filter.inArray
              ? inArray(actionTable.app, [...filter.appIdList])
              : // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  notInArray(actionTable.app, [...filter.appIdList]),
                  isNull(actionTable.app),
                )!,
          )
        }
        break
      }
      case 'asset': {
        if (filter.assetIdList.length === 1) {
          mut_whereConditions.push(
            filter.inArray
              ? like(actionTable.relatedAssetIds, `%${filter.assetIdList[0]}%`)
              : notLike(
                  actionTable.relatedAssetIds,
                  `%${filter.assetIdList[0]}%`,
                ),
          )
        } else if (filter.assetIdList.length > 1) {
          if (filter.inArray) {
            mut_whereConditions.push(
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              or(
                ...filter.assetIdList.map((assetId) =>
                  like(actionTable.relatedAssetIds, `%${assetId}%`),
                ),
              )!,
            )
          } else {
            mut_whereConditions.push(
              ...filter.assetIdList.map((assetId) =>
                notLike(actionTable.relatedAssetIds, `%${assetId}%`),
              ),
            )
          }
        }
        break
      }
      case 'category': {
        // category id を source id の prefix に変換
        const prefixList = filter.categoryIdList.map((categoryId) => {
          // EVM Chain category の場合
          const evmChain = blankApiKeyEvmChainList.find(
            (chain) => chain.network === categoryId,
          )
          if (evmChain !== undefined) {
            return `evm_${evmChain.id}_0x`
          }
          // Polkadot Chain category の場合
          const polkadotChain = blankApiKeyPolkadotChains.find(
            (chain) => chain.network === categoryId,
          )
          if (polkadotChain !== undefined) {
            return `polkadot_${polkadotChain.id}_`
          }

          // それ以外の場合
          return `${categoryId}_`
        })

        if (prefixList.length === 1) {
          mut_whereConditions.push(
            filter.inArray
              ? like(actionTable.sourceId, `${prefixList[0]}%`)
              : notLike(actionTable.sourceId, `${prefixList[0]}%`),
          )
        } else if (prefixList.length > 1) {
          if (filter.inArray) {
            mut_whereConditions.push(
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              or(
                ...prefixList.map((prefix) =>
                  like(actionTable.sourceId, `${prefix}%`),
                ),
              )!,
            )
          } else {
            mut_whereConditions.push(
              ...prefixList.map((prefix) =>
                notLike(actionTable.sourceId, `${prefix}%`),
              ),
            )
          }
        }
        break
      }
      case 'comment': {
        if (filter.value.length > 0) {
          mut_whereConditions.push(
            filter.isMatch
              ? eq(actionTable.comment, filter.value)
              : like(actionTable.comment, `%${filter.value}%`),
          )
        }
        break
      }
      case 'cross-id': {
        if (filter.value.length > 0) {
          mut_whereConditions.push(
            filter.isMatch
              ? eq(actionTable.crossId, filter.value)
              : ne(actionTable.crossId, filter.value),
          )
        }
        break
      }
      case 'error': {
        if (filter.errorList.length === 1) {
          switch (filter.errorList[0]) {
            case 'insufficient-amont': {
              mut_whereConditions.push(
                filter.inArray
                  ? eq(actionTable.hasInsufficientAmountTx, true)
                  : ne(actionTable.hasInsufficientAmountTx, true),
              )
              break
            }
            case 'need-check': {
              mut_whereConditions.push(
                filter.inArray
                  ? eq(actionTable.evidence, 'none')
                  : ne(actionTable.evidence, 'none'),
              )
              break
            }
            case 'unknown-price': {
              mut_whereConditions.push(
                filter.inArray
                  ? eq(actionTable.hasUnknownPriceTx, true)
                  : ne(actionTable.hasUnknownPriceTx, true),
              )
              break
            }
          }
        } else if (filter.errorList.length > 1) {
          if (filter.inArray) {
            mut_whereConditions.push(
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              or(
                ...filter.errorList.map((error) => {
                  switch (error) {
                    case 'insufficient-amont': {
                      return eq(actionTable.hasInsufficientAmountTx, true)
                    }
                    case 'need-check': {
                      return eq(actionTable.evidence, 'none')
                    }
                    case 'unknown-price': {
                      return eq(actionTable.hasUnknownPriceTx, true)
                    }
                  }
                }),
              )!,
            )
          } else {
            mut_whereConditions.push(
              ...filter.errorList.map((error) => {
                switch (error) {
                  case 'insufficient-amont': {
                    return ne(actionTable.hasInsufficientAmountTx, true)
                  }
                  case 'need-check': {
                    return ne(actionTable.evidence, 'none')
                  }
                  case 'unknown-price': {
                    return ne(actionTable.hasUnknownPriceTx, true)
                  }
                }
              }),
            )
          }
        }
        break
      }
      case 'evidence': {
        if (filter.evidenceList.length === 1) {
          mut_whereConditions.push(
            filter.inArray
              ? eq(actionTable.evidence, filter.evidenceList[0])
              : ne(actionTable.evidence, filter.evidenceList[0]),
          )
        } else if (filter.evidenceList.length > 1) {
          mut_whereConditions.push(
            filter.inArray
              ? inArray(actionTable.evidence, [...filter.evidenceList])
              : notInArray(actionTable.evidence, [...filter.evidenceList]),
          )
        }
        break
      }
      case 'loan-id': {
        if (filter.value.length > 0) {
          mut_whereConditions.push(
            filter.isMatch
              ? eq(actionTable.loanId, filter.value)
              : ne(actionTable.loanId, filter.value),
          )
        }
        break
      }
      case 'timestamp': {
        if (filter.from !== undefined && filter.to !== undefined) {
          mut_whereConditions.push(
            gte(actionTable.timestamp, new Date(filter.from)),
            lte(actionTable.timestamp, new Date(filter.to)),
          )
        }
        break
      }
      case 'transfer': {
        if (filter.addressList.length === 1) {
          switch (filter.operator) {
            case 'in-array': {
              mut_whereConditions.push(
                // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  like(actionTable.fromAddresses, `%${filter.addressList[0]}%`),
                  like(actionTable.toAddresses, `%${filter.addressList[0]}%`),
                )!,
              )
              break
            }
            case 'in-array-from': {
              mut_whereConditions.push(
                like(actionTable.fromAddresses, `%${filter.addressList[0]}%`),
              )
              break
            }
            case 'in-array-to': {
              mut_whereConditions.push(
                like(actionTable.toAddresses, `%${filter.addressList[0]}%`),
              )
              break
            }
            case 'not-in-array': {
              mut_whereConditions.push(
                notLike(
                  actionTable.fromAddresses,
                  `%${filter.addressList[0]}%`,
                ),
                notLike(actionTable.toAddresses, `%${filter.addressList[0]}%`),
              )
              break
            }
            case 'not-in-array-from': {
              mut_whereConditions.push(
                notLike(
                  actionTable.fromAddresses,
                  `%${filter.addressList[0]}%`,
                ),
              )
              break
            }
            case 'not-in-array-to': {
              mut_whereConditions.push(
                notLike(actionTable.toAddresses, `%${filter.addressList[0]}%`),
              )
              break
            }
          }
        } else if (filter.addressList.length > 1) {
          // TODO
          switch (filter.operator) {
            case 'in-array': {
              mut_whereConditions.push(
                // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  ...filter.addressList.flatMap((address) => [
                    like(actionTable.fromAddresses, `%${address}%`),
                    like(actionTable.toAddresses, `%${address}%`),
                  ]),
                )!,
              )
              break
            }
            case 'in-array-from': {
              mut_whereConditions.push(
                // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  ...filter.addressList.map((address) =>
                    like(actionTable.fromAddresses, `%${address}%`),
                  ),
                )!,
              )
              break
            }
            case 'in-array-to': {
              mut_whereConditions.push(
                // biome-ignore lint/style/noNonNullAssertion: <explanation>
                or(
                  ...filter.addressList.map((address) =>
                    like(actionTable.toAddresses, `%${address}%`),
                  ),
                )!,
              )
              break
            }
            case 'not-in-array': {
              mut_whereConditions.push(
                ...filter.addressList.flatMap((address) => [
                  notLike(actionTable.fromAddresses, `%${address}%`),
                  notLike(actionTable.toAddresses, `%${address}%`),
                ]),
              )
              break
            }
            case 'not-in-array-from': {
              mut_whereConditions.push(
                ...filter.addressList.map((address) =>
                  notLike(actionTable.fromAddresses, `%${address}%`),
                ),
              )
              break
            }
            case 'not-in-array-to': {
              mut_whereConditions.push(
                ...filter.addressList.map((address) =>
                  notLike(actionTable.toAddresses, `%${address}%`),
                ),
              )
              break
            }
          }
        }
        break
      }
    }
  }
  return mut_whereConditions
}
