import type { Action } from '@0xtorch/core'
import { createPolkadotAddress, createPolkadotSource } from '@0xtorch/polkadot'
import { divideArrayIntoChunks } from '@pkg/basic'
import { and, eq, inArray, sql } from 'drizzle-orm'
import {
  actionAccountRelationColumnCount,
  actionColumnCount,
  actionSourceColumnCount,
  actionTransferColumnCount,
  cryptoCurrencyColumnCount,
  nftColumnCount,
  sqliteMaxCompoundSelectCount,
  sqliteMaxHostParameterCount,
} from '../../constants'
import { actionsToInsertSqls } from '../../createSql/actionsToInsertSqls'
import {
  accountPolkadotExtrinsicRelationTable,
  accountTable,
  accountingTransactionTable,
  accountingTransactionTransferTable,
  actionAccountRelationTable,
  actionSourceTable,
  actionTable,
  actionTransferTable,
  cryptoCurrencyTable,
  nftTable,
  portfolioTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'
import { conflictUpdateAllExcept, getMaxInsertRowCount } from '../../utils'

type SavePolkadotActionsParameters = {
  database: DatabaseWithTransaction
  chainId: number
  blocks: readonly {
    blockNumber: number
    extrinsicIndexes: (number | undefined)[]
  }[]
  actions: readonly Action[]
}

/** Polkadot block data から生成したデータを保存
 * - portfolio.isTxGenerated を false に更新
 * - block に関連する accountPolkadotExtrinsicRelation.analyzed を true に更新
 * - block > extrinsic を actionSource に保存 (upsert)
 * - actionSource に関連する既存データを削除
 *   - action account relation
 *   - accounting transaction transfer
 *   - accounting transaction
 *   - action transfer
 *   - action
 * - action 関連データを保存
 *   - crypto currency
 *   - nft
 *   - action
 *   - action transfer
 *   - action account relation
 * - account.syncing を更新
 */
export const savePolkadotActions = async ({
  database: { database, transaction },
  chainId,
  blocks,
  actions,
}: SavePolkadotActionsParameters) => {
  if (blocks.length === 0 && actions.length === 0) {
    return
  }

  const blockNumbers: number[] = []
  const sources: string[] = []
  const actionSourceInsertRows: {
    id: string
    type: 'polkadot'
    polkadotChainId: number
    polkadotBlockNumber: number
    polkadotExtrinsicIndex?: number
  }[] = []
  for (const block of blocks) {
    blockNumbers.push(block.blockNumber)
    for (const extrinsicIndex of block.extrinsicIndexes) {
      const id = createPolkadotSource({
        chainId,
        blockNumber: block.blockNumber,
        extrinsicIndex,
      })
      sources.push(id)
      actionSourceInsertRows.push({
        id,
        type: 'polkadot',
        polkadotChainId: chainId,
        polkadotBlockNumber: block.blockNumber,
        polkadotExtrinsicIndex: extrinsicIndex,
      })
    }
  }

  const accountRows = await database
    .select({ id: accountTable.id, address: accountTable.polkadotAddress })
    .from(accountTable)
    .where(eq(accountTable.polkadotChainId, chainId))
  const accountIds: number[] = []
  const accounts: { id: number; address: string }[] = []
  for (const row of accountRows) {
    accountIds.push(row.id)
    if (row.address === null) {
      continue
    }
    accounts.push({ id: row.id, address: row.address })
  }
  const actionAccountRelations = generateActionAccountRelations({
    chainId,
    actions,
    accounts,
  })

  const { actionSqls, actionTransferSqls, cryptoSqls, nftSqls } =
    actionsToInsertSqls(actions)

  await transaction([
    (tx) => [
      // portfolio.isTxGenerated を false に更新
      tx
        .update(portfolioTable)
        .set({ isTxGenerated: false }),
      // block に関連する accountPolkadotExtrinsicRelation.analyzed を true に更新
      ...divideArrayIntoChunks(
        blockNumbers,
        sqliteMaxHostParameterCount - accountIds.length - 2,
      ).map((chunk) =>
        tx
          .update(accountPolkadotExtrinsicRelationTable)
          .set({ analyzed: true })
          .where(
            and(
              inArray(
                accountPolkadotExtrinsicRelationTable.accountId,
                accountIds,
              ),
              inArray(accountPolkadotExtrinsicRelationTable.blockNumber, [
                ...chunk,
              ]),
            ),
          ),
      ),
      // block > extrinsic を actionSource に保存 (upsert)
      ...divideArrayIntoChunks(
        actionSourceInsertRows,
        getMaxInsertRowCount(actionSourceColumnCount, true),
      ).map((chunk) =>
        tx
          .insert(actionSourceTable)
          .values([...chunk])
          .onConflictDoUpdate({
            target: [actionSourceTable.id],
            set: conflictUpdateAllExcept(actionSourceTable, ['id']),
          }),
      ),
      // actionSource に関連する action account relation 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionAccountRelationTable).where(
            inArray(
              actionAccountRelationTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // actionSource に関連する accounting transaction transfer 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTransferTable).where(
            inArray(
              accountingTransactionTransferTable.transactionId,
              tx
                .select({ id: accountingTransactionTable.id })
                .from(accountingTransactionTable)
                .leftJoin(
                  actionTable,
                  eq(accountingTransactionTable.actionId, actionTable.id),
                )
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // actionSource に関連する accounting transaction 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(accountingTransactionTable).where(
            inArray(
              accountingTransactionTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
      // actionSource に関連する action transfer 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx.delete(actionTransferTable).where(
            inArray(
              actionTransferTable.actionId,
              tx
                .select({ id: actionTable.id })
                .from(actionTable)
                .where(inArray(actionTable.sourceId, [...chunk])),
            ),
          ),
      ),
    ],
    (tx) => [
      // actionSource に関連する action 削除
      ...divideArrayIntoChunks(sources, sqliteMaxHostParameterCount).map(
        (chunk) =>
          tx
            .delete(actionTable)
            .where(inArray(actionTable.sourceId, [...chunk])),
      ),
    ],
    (tx) => [
      // crypto
      ...divideArrayIntoChunks(
        cryptoSqls,
        getMaxInsertRowCount(cryptoCurrencyColumnCount),
      ).map((chunk) =>
        tx
          .insert(cryptoCurrencyTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // nft
      ...divideArrayIntoChunks(
        nftSqls,
        getMaxInsertRowCount(nftColumnCount),
      ).map((chunk) =>
        tx
          .insert(nftTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // action
      ...divideArrayIntoChunks(
        actionSqls,
        // SQLITE_MAX_COMPOUND_SELECT を意識して数を調整
        Math.min(
          getMaxInsertRowCount(actionColumnCount + 3),
          Math.floor(sqliteMaxCompoundSelectCount / 3),
        ),
      ).map((chunk) => tx.insert(actionTable).values([...chunk])),
      // action transfer
      ...divideArrayIntoChunks(
        actionTransferSqls,
        getMaxInsertRowCount(actionTransferColumnCount),
      ).map((chunk) => tx.insert(actionTransferTable).values([...chunk])),
      // action account relation
      ...divideArrayIntoChunks(
        actionAccountRelations,
        getMaxInsertRowCount(actionAccountRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(actionAccountRelationTable)
          .values([...chunk])
          .onConflictDoNothing(),
      ),
      // account.syncing を analyzed=false の accountPolkadotExtrinsicRelation の存在に合わせて更新
      ...accountIds.map((id) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountPolkadotExtrinsicRelationTable} WHERE ${accountPolkadotExtrinsicRelationTable.accountId} = ${id} AND ${accountPolkadotExtrinsicRelationTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, id)),
      ),
    ],
  ])
}

/** action から actionAccountRelation data を作成
 * - action transfer の from, to に account address が含まれていれば account に関連づける
 */
const generateActionAccountRelations = (params: {
  chainId: number
  actions: readonly Action[]
  accounts: readonly { id: number; address: string }[]
}): readonly { actionId: string; accountId: number }[] => {
  const { chainId, actions, accounts } = params

  const polkadotAddressIdMap = new Map<string, number>(
    accounts.map(({ address, id }) => [
      createPolkadotAddress({ chainId, address }),
      id,
    ]),
  )

  const relationMap = new Map<string, { actionId: string; accountId: number }>()
  for (const action of actions) {
    const actionId = `${action.source}_${action.order}`

    // action transfer の from, to と一致する account を追加
    for (const transfer of action.transfers) {
      const transferFromAccountId =
        transfer.from === undefined
          ? undefined
          : polkadotAddressIdMap.get(transfer.from)
      if (transferFromAccountId !== undefined) {
        const id = `${actionId}/${transferFromAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferFromAccountId,
          })
        }
      }
      const transferToAccountId =
        transfer.to === undefined
          ? undefined
          : polkadotAddressIdMap.get(transfer.to)
      if (transferToAccountId !== undefined) {
        const id = `${actionId}/${transferToAccountId}`
        if (!relationMap.has(id)) {
          relationMap.set(actionId, {
            actionId,
            accountId: transferToAccountId,
          })
        }
      }
    }
  }

  return [...relationMap.values()]
}
