import {
  type BigDecimal,
  createBigDecimal,
  divide,
  times,
} from '@0xtorch/big-decimal'
import type { CryptoCurrencyDataSource } from '@0xtorch/core'
import type { AssetFetchClient } from './createAssetFetchClient'

type PricesWithTimestamp = {
  timestamps: readonly number[]
  prices: Map<number, BigDecimal>
}

export const createCryptoCurrencyDataSource = (
  client: AssetFetchClient,
): CryptoCurrencyDataSource => ({
  getHistoricalPrices: async ({
    targetCurrency,
    vsCurrency,
    timestampList,
  }) => {
    if (timestampList.length === 0) {
      return new Map<number, BigDecimal>()
    }
    if (
      vsCurrency.id !== 'eur' &&
      vsCurrency.id !== 'usd' &&
      vsCurrency.id !== 'jpy'
    ) {
      throw new Error(`Not supported vs currency: ${vsCurrency.id}`)
    }

    // targetCurrency に priceDatasourceId が設定されている場合は、その priceDatasourceId で price 取得
    const targetCurrencyId =
      targetCurrency.priceDatasourceId ?? targetCurrency.id

    // EVM / Solana token の場合は価格データ無いので空配列を返す
    if (
      targetCurrencyId.startsWith('evm_') ||
      targetCurrencyId.startsWith('solana_')
    ) {
      return new Map<number, BigDecimal>()
    }

    // timestamps をもとに取得対象日付 list (昇順) 作成
    const days = createDaysByTimestamps(timestampList)

    // 日付毎の csv file 取得
    const [targetPrices, usdBtcPrices, vsBtcPrices] = await Promise.all([
      getPricesOfDays({
        client,
        target: targetCurrencyId,
        vs: targetCurrencyId === 'bitcoin' ? vsCurrency.id : 'usd',
        days,
      }),
      targetCurrencyId === 'bitcoin' || vsCurrency.id === 'usd'
        ? Promise.resolve({
            timestamps: [] satisfies number[],
            prices: new Map<number, BigDecimal>(),
          })
        : getPricesOfDays({
            client,
            target: 'bitcoin',
            vs: 'usd',
            days,
          }),
      targetCurrencyId === 'bitcoin' || vsCurrency.id === 'usd'
        ? Promise.resolve({
            timestamps: [] satisfies number[],
            prices: new Map<number, BigDecimal>(),
          } satisfies PricesWithTimestamp)
        : getPricesOfDays({
            client,
            target: 'bitcoin',
            vs: vsCurrency.id,
            days,
          }),
    ])

    return createHistoricalPrices({
      target: targetCurrencyId,
      vs: vsCurrency.id,
      timestamps: timestampList,
      targetPrices,
      usdBtcPrices,
      vsBtcPrices,
    })
  },
})

const twoHoursInMs = 1000 * 60 * 60 * 2

const createDaysByTimestamps = (timestamps: readonly number[]) => {
  const daySet = new Set<string>()
  for (const timestamp of timestamps) {
    daySet.add(createDayByTimestamp(timestamp - twoHoursInMs))
    daySet.add(createDayByTimestamp(timestamp))
    daySet.add(createDayByTimestamp(timestamp + twoHoursInMs))
  }
  return [...daySet].sort()
}

const createDayByTimestamp = (timestamp: number) =>
  new Date(timestamp).toISOString().split('T')[0]

const getPricesOfDays = async ({
  client,
  target,
  vs,
  days,
}: {
  client: AssetFetchClient
  target: string
  vs: string
  days: string[]
}): Promise<PricesWithTimestamp> => {
  const timestamps: number[] = []
  const prices = new Map<number, BigDecimal>()
  const results = await Promise.all(
    days.map((day) =>
      getPricesOfDay({
        client,
        target,
        vs,
        day,
      }),
    ),
  )
  for (const result of results) {
    timestamps.push(...result.timestamps)
    for (const [timestamp, price] of result.prices) {
      prices.set(timestamp, price)
    }
  }
  return {
    timestamps,
    prices,
  }
}

const priceCache = new Map<
  string,
  {
    timestamps: readonly number[]
    prices: Map<number, BigDecimal>
  }
>()

const getPricesOfDay = async ({
  client,
  target,
  vs,
  day,
}: {
  client: AssetFetchClient
  target: string
  vs: string
  day: string
}): Promise<PricesWithTimestamp> => {
  const key = `${target}/${vs}/${day}`

  // キャッシュがあればそれを返す
  const cached = priceCache.get(key)
  if (cached !== undefined) {
    return cached
  }

  // キャッシュが無い場合は API request してキャッシュを更新する
  const response = await client.get(`/prices/${target}/${vs}/${day}.csv`)
  if (!response.ok) {
    // 404 は空データとしてキャッシュに保存
    if (response.status === 404) {
      priceCache.set(key, {
        timestamps: [],
        prices: new Map(),
      })
    }
    return {
      timestamps: [],
      prices: new Map(),
    }
  }

  // CSV パースして price data 作成
  const csv = await response.text()
  const timestamps: number[] = []
  const prices = new Map<number, BigDecimal>()
  for (const [index, row] of csv.split('\n').entries()) {
    if (index === 0 || row.length === 0) {
      continue
    }
    const [timestampText, priceText] = row
      .split(',')
      .map((value) => value.trim())
    const timestamp = Number.parseInt(timestampText)
    const price = createBigDecimal(priceText)
    timestamps.push(timestamp)
    prices.set(timestamp, price)
  }
  const result = {
    timestamps,
    prices,
  }

  // キャッシュに保存
  priceCache.set(key, result)

  // API request で取得したデータを返す
  return result
}

const createHistoricalPrices = ({
  target,
  vs,
  timestamps,
  targetPrices,
  usdBtcPrices,
  vsBtcPrices,
}: {
  target: string
  vs: string
  timestamps: readonly number[]
  targetPrices: PricesWithTimestamp
  usdBtcPrices: PricesWithTimestamp
  vsBtcPrices: PricesWithTimestamp
}): Map<number, BigDecimal> => {
  const prices = new Map<number, BigDecimal>()

  for (const timestamp of timestamps) {
    const targetPrice = getPrice({
      prices: targetPrices,
      timestamp,
    })
    if (targetPrice === undefined) {
      continue
    }
    if (target === 'bitcoin' || vs === 'usd') {
      prices.set(timestamp, targetPrice)
      continue
    }
    const usdPrice = getPrice({
      prices: usdBtcPrices,
      timestamp,
    })
    if (usdPrice === undefined) {
      continue
    }
    const vsPrice = getPrice({
      prices: vsBtcPrices,
      timestamp,
    })
    if (vsPrice === undefined) {
      continue
    }
    prices.set(timestamp, divide(times(targetPrice, vsPrice, 18), usdPrice, 18))
  }

  return prices
}

const getPrice = ({
  prices: { timestamps, prices },
  timestamp,
}: {
  prices: PricesWithTimestamp
  timestamp: number
}): BigDecimal | undefined => {
  const oneHourInMs = 1000 * 60 * 60
  const minTimestamp = timestamp - oneHourInMs
  const maxTimestamp = timestamp + oneHourInMs

  let left = 0
  let right = timestamps.length - 1
  let closestIndex = -1
  let minDifference = Number.POSITIVE_INFINITY

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)
    const midTimestamp = timestamps[mid]

    if (midTimestamp < minTimestamp) {
      left = mid + 1
    } else if (midTimestamp > maxTimestamp) {
      right = mid - 1
    } else {
      const difference = Math.abs(midTimestamp - timestamp)
      if (difference < minDifference) {
        minDifference = difference
        closestIndex = mid
      }
      if (midTimestamp < timestamp) {
        left = mid + 1
      } else {
        right = mid - 1
      }
    }
  }

  return closestIndex === -1 ? undefined : prices.get(timestamps[closestIndex])
}
