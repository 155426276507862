import { divideArrayIntoChunks } from '@pkg/basic'
import { eq, sql } from 'drizzle-orm'
import {
  accountPolkadotExtrinsicRelationColumnCount,
  polkadotExtrinsicColumnCount,
} from '../../constants'
import {
  accountPolkadotExtrinsicRelationTable,
  accountTable,
  polkadotExtrinsicTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'
import { getMaxInsertRowCount } from '../../utils'

type SavePolkadotAccountIndexesParameters = {
  database: DatabaseWithTransaction
  accountId: number
  chainId: number
  extrinsics: readonly {
    blockNumber: number
    extrinsicIndex: number | null
    timestamp: Date
  }[]
}

/** polkadot account index (block & extrinsic) data を保存
 * - polkadot extrinsic を重複しない場合のみ保存
 * - account polkadot extrinsic を重複しない場合のみ analyzed = false で保存
 * - account の syncing , lastSyncedAt, polkadotToBlock を更新
 */
export const savePolkadotAccountIndexes = async ({
  database: { transaction },
  accountId,
  chainId,
  extrinsics,
}: SavePolkadotAccountIndexesParameters) => {
  const toBlock = Math.max(
    1,
    ...extrinsics.map(({ blockNumber }) => blockNumber),
  )

  await transaction([
    (tx) => [
      // polkadot extrinsic を重複しない場合のみ保存
      ...divideArrayIntoChunks(
        extrinsics,
        getMaxInsertRowCount(polkadotExtrinsicColumnCount),
      ).map((chunk) =>
        tx
          .insert(polkadotExtrinsicTable)
          .values(
            chunk.map((item) => ({
              chainId,
              blockNumber: item.blockNumber,
              extrinsicIndex: item.extrinsicIndex,
              timestamp: item.timestamp,
            })),
          )
          .onConflictDoNothing(),
      ),
      // account polkadot extrinsic を重複しない場合のみ analyzed = false で保存
      ...divideArrayIntoChunks(
        extrinsics,
        getMaxInsertRowCount(accountPolkadotExtrinsicRelationColumnCount),
      ).map((chunk) =>
        tx
          .insert(accountPolkadotExtrinsicRelationTable)
          .values(
            chunk.map((item) => ({
              accountId,
              blockNumber: item.blockNumber,
              extrinsicIndex: item.extrinsicIndex,
              analyzed: false,
            })),
          )
          .onConflictDoNothing(),
      ),
    ],
    (tx) => [
      // account の syncing , lastSyncedAt を更新
      tx
        .update(accountTable)
        .set({
          syncing: sql`(EXISTS (SELECT 1 FROM ${accountPolkadotExtrinsicRelationTable} WHERE ${accountPolkadotExtrinsicRelationTable.accountId} = ${accountId} AND ${accountPolkadotExtrinsicRelationTable.analyzed} = ${false}))`,
          lastSyncedAt: new Date(),
          polkadotToBlock: sql`(CASE WHEN ${accountTable.polkadotToBlock} IS NULL OR ${accountTable.polkadotToBlock} < ${toBlock} THEN ${toBlock} ELSE ${accountTable.polkadotToBlock} END)`,
        })
        .where(eq(accountTable.id, accountId)),
    ],
  ])
}
