import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import {
  accountEvmCsvTable,
  accountExchangeApiTable,
  accountSolanaSignatureTable,
  accountTable,
  accountingPeriodTable,
  actionSourceTable,
  singleActionRuleTable,
} from './schema'

export const insertAccountEvmSchema = createInsertSchema(accountTable)
  .extend({
    type: z.literal('evm'),
    evmChainId: z.number().int(),
  })
  .omit({
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    solanaAddress: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const accountPolkadotSchema = createSelectSchema(accountTable)
  .extend({
    type: z.literal('polkadot'),
    polkadotChainId: z.number().int(),
    polkadotAddress: z.string().regex(/^\w+$/),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    solanaAddress: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const accountSolanaSchema = createSelectSchema(accountTable)
  .extend({
    type: z.literal('solana'),
    solanaAddress: z.string().regex(/^\w+$/),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const insertAccountSolanaSchema = createInsertSchema(accountTable)
  .extend({
    type: z.literal('solana'),
    solanaAddress: z.string().regex(/^\w+$/),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const accountExchangeSchema = createSelectSchema(accountTable)
  .extend({
    type: z.literal('exchange'),
    exchangeApiKey: z
      .string()
      .nullable()
      .transform((v) => v ?? undefined),
    exchangeApiSecret: z
      .string()
      .nullable()
      .transform((v) => v ?? undefined),
    exchangeApiPassword: z
      .string()
      .nullable()
      .transform((v) => v ?? undefined),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    solanaAddress: true,
  })

export const insertAccountExchangeSchema = createInsertSchema(accountTable)
  .extend({
    type: z.literal('exchange'),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    solanaAddress: true,
  })

export const accountServiceSchema = createSelectSchema(accountTable)
  .extend({
    type: z.literal('service'),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    solanaAddress: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const insertAccountServiceSchema = createInsertSchema(accountTable)
  .extend({
    type: z.literal('service'),
  })
  .omit({
    evmChainId: true,
    evmAddress: true,
    evmToBlock: true,
    polkadotChainId: true,
    polkadotAddress: true,
    polkadotToBlock: true,
    solanaAddress: true,
    exchangeApiKey: true,
    exchangeApiSecret: true,
    exchangeApiPassword: true,
  })

export const accountExchangeApiSchema = createSelectSchema(
  accountExchangeApiTable,
)

export const accountEvmCsvSchema = createSelectSchema(accountEvmCsvTable)

export const accountSolanaSignatureSchema = createSelectSchema(
  accountSolanaSignatureTable,
).extend({
  signature: z.string().regex(/^\w+$/),
})

export const actionSourcePolkadotSchema = createSelectSchema(actionSourceTable)
  .extend({
    type: z.literal('polkadot'),
    polkadotChainId: z.number().int(),
    polkadotBlockNumber: z.number().int(),
    polkadotExtrinsicIndex: z.number().int().optional().nullable(),
  })
  .omit({
    evmChainId: true,
    evmHash: true,
    evmFrom: true,
    evmTo: true,
    evmStatus: true,
    evmFunctionId: true,
    evmFunctionName: true,
    solanaSignature: true,
    solanaStatus: true,
  })

export const actionSourceSolanaSchema = createSelectSchema(actionSourceTable)
  .extend({
    type: z.literal('solana'),
    solanaSignature: z.string().regex(/^\w+$/),
    solanaStatus: z.union([z.literal('success'), z.literal('reverted')]),
  })
  .omit({
    evmChainId: true,
    evmHash: true,
    evmFrom: true,
    evmTo: true,
    evmStatus: true,
    evmFunctionId: true,
    evmFunctionName: true,
    polkadotBlockNumber: true,
    polkadotChainId: true,
    polkadotExtrinsicIndex: true,
  })

export const insertActionSourceSolanaSchema = createInsertSchema(
  actionSourceTable,
)
  .extend({
    solanaSignature: z.string().regex(/^\w+$/),
    solanaStatus: z.union([z.literal('success'), z.literal('reverted')]),
  })
  .omit({
    evmChainId: true,
    evmHash: true,
    evmFrom: true,
    evmTo: true,
    evmStatus: true,
    evmFunctionId: true,
    evmFunctionName: true,
  })

export const insertSingleActionRuleSchema = createInsertSchema(
  singleActionRuleTable,
)

export const accountingPeriodSchema = createSelectSchema(
  accountingPeriodTable,
).extend({
  start: z.date().transform((v) => v.getTime()),
  end: z.date().transform((v) => v.getTime()),
})

export const insertAccountingPeriodSchema = createInsertSchema(
  accountingPeriodTable,
).extend({
  id: z.number().int().positive(),
})
