import { Button } from '@/components/ui/button'
import { cryptoCurrencyDataSource } from '@/constants/datasource'
import { fiatCurrencies } from '@/constants/fiatCurrency'
import { cn } from '@/lib/utils'
import type { PortfolioPageData } from '@/types'
import {
  createCalculateTransactionPlByWacbWorker,
  createCreateAssetBalancesForWacbWorker,
  createCreateTransactionsWorker,
} from '@/workers'
import { generateAccountingTransactionList } from '@pkg/analyze'
import { selectPortfolio } from '@pkg/database-portfolio'
import { MixIcon } from '@radix-ui/react-icons'
import { terminate } from '@shopify/web-worker'
import { useAtom } from 'jotai'
import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import {
  isCalculatingAtom,
  isRequiredCalculationAtom,
  updateIsRequiredCalculationAtom,
} from '../../states/calculate'
import { progressesAtom } from '../../states/progress'

const UpdatingTransactionToast = (
  props: Pick<
    PortfolioPageData,
    'readFromDatabase' | 'writeToDatabase' | 'readonly'
  >,
) => {
  const { readonly, readFromDatabase, writeToDatabase } = props

  const [progresses] = useAtom(progressesAtom)
  const [isRequiredCalculation] = useAtom(isRequiredCalculationAtom)
  const [, updateIsRequiredCalculation] = useAtom(
    updateIsRequiredCalculationAtom,
  )
  const [isCalculating, setIsCalculating] = useAtom(isCalculatingAtom)
  const calculate = async () => {
    if (!isRequiredCalculation) {
      return
    }
    if (writeToDatabase === undefined) {
      return
    }
    setIsCalculating(true)
    const createTransactionsWorker = createCreateTransactionsWorker()
    const calculateTransactionPlByWacbWorker =
      createCalculateTransactionPlByWacbWorker()
    const createAssetBalancesForWacbWorker =
      createCreateAssetBalancesForWacbWorker()
    try {
      const portfolio = await readFromDatabase((database) =>
        selectPortfolio({ database }),
      )
      if (portfolio === undefined) {
        throw new Error('Portfolio not found')
      }
      const fiatCurrency = fiatCurrencies.find(
        ({ id }) => portfolio.fiat === id,
      )
      if (fiatCurrency === undefined) {
        throw new Error(`Fiat currency not found: ${portfolio.fiat}`)
      }
      await writeToDatabase((database) =>
        generateAccountingTransactionList({
          database,
          fiatCurrency,
          fiatCurrencyList: fiatCurrencies,
          cryptoCurrencyDataSource,
          createTransactions: (parameters) =>
            createTransactionsWorker.createTransactions(parameters),
          createAssetBalancesForWacb: (parameters) =>
            createAssetBalancesForWacbWorker.createAssetBalancesForWacb(
              parameters,
            ),
          calculateTransactionPlByWacb: (parameters) =>
            calculateTransactionPlByWacbWorker.calculateTransactionPlByWacb(
              parameters,
            ),
        }),
      )
      await updateIsRequiredCalculation({ readFromDatabase })
    } catch (error) {
      console.error(error)
    } finally {
      setIsCalculating(false)
      terminate(createTransactionsWorker)
      terminate(calculateTransactionPlByWacbWorker)
      terminate(createAssetBalancesForWacbWorker)
    }
  }

  return (
    <UI
      isHidden={readonly || progresses.length > 0 || !isRequiredCalculation}
      isCalculating={isCalculating}
      onCalculate={calculate}
    />
  )
}

const UI = ({
  isHidden,
  isCalculating,
  onCalculate,
}: {
  readonly isHidden: boolean
  readonly isCalculating: boolean
  readonly onCalculate: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(
        'absolute top-1 right-0 left-0 z-50 mx-auto inline-block w-80 duration-500',
        isHidden && 'translate-y-[-64px]',
      )}
    >
      <div className="flex w-full items-center justify-between space-x-4 rounded-full border border-primary/75 bg-background px-4 py-2 shadow">
        <p className="font-semibold text-muted-foreground text-sm">
          {t('Profit/Loss calculation is incompleted')}
        </p>
        <Button
          size="sm"
          disabled={isCalculating}
          onClick={onCalculate}
          className="h-auto rounded-full py-1.5"
        >
          {isCalculating ? <Loader2 className="animate-spin" /> : <MixIcon />}
          <span>{t('toast/update-transaction/button/calculate')}</span>
        </Button>
      </div>
    </div>
  )
}

export default UpdatingTransactionToast
