import {
  getPortfolioUsedAtFromLocalStorage,
  setPortfolioIsWritableToLocalStorage,
  setPortfolioToLocalStorage,
  setPortfolioUsedAtToLocalStorage,
} from '@/localStorage'
import type { Portfolio } from '@/types'
import {
  createReadFromDatabase,
  createWriteToDatabase,
  overwriteDatabase,
} from '@/utils/database'
import { portfolioSyncInterval } from '@pkg/basic'
import type { SetupPortfolioReturnTypes } from '.'
import { migration } from '../../logic/migration'
import { syncAfterSetup } from '../../logic/syncAfterSetup'

export const syncPortfolio = async (params: {
  token: string
  portfolios: readonly Portfolio[]
  id: string
  dbFile: Blob | undefined
}): Promise<SetupPortfolioReturnTypes> => {
  const { token, portfolios, id, dbFile } = params

  const portfolio = portfolios.find((p) => p.uuid === id)
  if (portfolio === undefined) {
    throw new Error(`Portfolio not found: ${id}`)
  }

  if (dbFile !== undefined) {
    // DB file があれば DB ファイル更新
    await overwriteDatabase(portfolio.uuid, dbFile)
  }

  // local storage 更新
  setPortfolioToLocalStorage(portfolio)
  const readonly =
    portfolio.plan !== 'free' &&
    portfolio.plan !== 'mini' &&
    (!portfolio.isWriter ||
      portfolio.lastUsedAt >
        Math.max(
          getPortfolioUsedAtFromLocalStorage() ?? 0,
          Date.now() - portfolioSyncInterval * 2,
        ))
  setPortfolioIsWritableToLocalStorage(!readonly)
  if (!readonly) {
    setPortfolioUsedAtToLocalStorage(portfolio.lastUsedAt, true)
  }

  // return data 作成
  const readFromDatabase = createReadFromDatabase(portfolio.uuid)
  const writeToDatabase = readonly
    ? undefined
    : createWriteToDatabase(portfolio.uuid)
  const portfolioPageData = {
    ...portfolio,
    readonly,
    readFromDatabase,
    writeToDatabase,
  }

  // migration
  await migration({
    readFromDatabase,
    writeToDatabase: writeToDatabase ?? readFromDatabase,
  })

  // sync 処理（完了を待たない）
  syncAfterSetup({
    ...portfolioPageData,
    token,
  })

  return {
    portfolios,
    portfolio: portfolioPageData,
  }
}
