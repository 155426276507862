import { createWorkerFactory } from '@shopify/web-worker'

export const createCalculateTransactionPlByWacbWorker = createWorkerFactory(
  () => import('./calculateTransactionPlByWacb'),
)

export const createCreateAssetBalancesForWacbWorker = createWorkerFactory(
  () => import('./createAssetBalancesForWacb'),
)

export const createCreateTransactionsWorker = createWorkerFactory(
  () => import('./createTransactions'),
)

export const createAnalyzeEvmTransactionWorker = createWorkerFactory(
  () => import('./analyzeEvmTransaction'),
)

export const createAnalyzeSolanaTransactionWorker = createWorkerFactory(
  () => import('./analyzeSolanaTransaction'),
)

export const createFetchBinanceApiDataWorker = createWorkerFactory(
  () => import('./saveBinanceApiData'),
)

export const createFetchBitbankApiDataWorker = createWorkerFactory(
  () => import('./saveBitbankApiData'),
)

export const createFetchBitgetApiDataWorker = createWorkerFactory(
  () => import('./saveBitgetApiData'),
)

export const createFetchBybitApiDataWorker = createWorkerFactory(
  () => import('./saveBybitApiData'),
)

export const createFetchEvmAddressDataWorker = createWorkerFactory(
  () => import('./fetchEvmAddressData'),
)

export const createFetchEvmTransactionListWorker = createWorkerFactory(
  () => import('./fetchEvmTransactionList'),
)

export const createFetchOkxApiDataWorker = createWorkerFactory(
  () => import('./saveOkxApiData'),
)

export const createFetchSolanaAddressDataWorker = createWorkerFactory(
  () => import('./fetchSolanaAddressData'),
)

export const createFetchSolanaTransactionsWorker = createWorkerFactory(
  () => import('./fetchSolanaTransactions'),
)

export const createGetPolkadotBlockDatasWorker = createWorkerFactory(
  () => import('./getPolkadotBlockDatas'),
)

export const createParseCsvFileToAccountActionsWorker = createWorkerFactory(
  () => import('./parseCsvFileToAccountActions'),
)

export const createParsePolkadotExtrinsicToActionsWorker = createWorkerFactory(
  () => import('./parsePolkadotExtrinsicToActions'),
)

export const createSaveGateApiDataWorker = createWorkerFactory(
  () => import('./saveGateApiData'),
)

export const createSaveKucoinApiDataWorker = createWorkerFactory(
  () => import('./saveKucoinApiData'),
)
