import type { CryptoCurrency } from '@0xtorch/core'
import { type SQL, and, inArray } from 'drizzle-orm'
import { parseToCryptoCurrency } from '../parsers/cryptoCurrency'
import { cryptoCurrencyTable } from '../schema'
import type { DatabaseWithTransaction } from '../types'

type SelectCryptoCurrenciesParameters = {
  database: DatabaseWithTransaction
  ids?: readonly string[]
}

export const selectCryptoCurrencies = async ({
  database: { database },
  ids,
}: SelectCryptoCurrenciesParameters): Promise<readonly CryptoCurrency[]> => {
  const whereConditions: SQL<unknown>[] = []
  if (ids !== undefined && ids.length > 0) {
    whereConditions.push(inArray(cryptoCurrencyTable.id, [...ids]))
  }
  let where: SQL<unknown> | undefined
  if (whereConditions.length === 0) {
    where = undefined
  } else if (whereConditions.length === 1) {
    where = whereConditions[0]
  } else {
    where = and(...whereConditions)
  }
  const data = await database.select().from(cryptoCurrencyTable).where(where)
  return data.map((item) => parseToCryptoCurrency(item))
}
