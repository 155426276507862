import { and, desc, eq, inArray } from 'drizzle-orm'
import {
  accountPolkadotExtrinsicRelationTable,
  accountTable,
  polkadotExtrinsicTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

type GetPolkadotIndexesParameters = {
  database: DatabaseWithTransaction
  chainId: number
  analyzed: boolean
}

type GetPolkadotIndexesReturnTypes = {
  extrinsics: readonly {
    blockNumber: number
    extrinsicIndex: number | undefined
    timestamp: number
  }[]
}

export const getPolkadotIndexes = async ({
  database: { database },
  chainId,
  analyzed,
}: GetPolkadotIndexesParameters): Promise<GetPolkadotIndexesReturnTypes> => {
  const blockRows = await database
    .selectDistinct({
      blockNumber: accountPolkadotExtrinsicRelationTable.blockNumber,
    })
    .from(accountPolkadotExtrinsicRelationTable)
    .leftJoin(
      accountTable,
      eq(accountPolkadotExtrinsicRelationTable.accountId, accountTable.id),
    )
    .where(
      and(
        eq(accountPolkadotExtrinsicRelationTable.analyzed, analyzed),
        eq(accountTable.polkadotChainId, chainId),
      ),
    )
  if (blockRows.length === 0) {
    return {
      extrinsics: [],
    }
  }

  const blockNumbers = blockRows.map(({ blockNumber }) => blockNumber)

  const extrinsicRows = await database
    .select()
    .from(polkadotExtrinsicTable)
    .where(
      and(
        eq(polkadotExtrinsicTable.chainId, chainId),
        inArray(polkadotExtrinsicTable.blockNumber, blockNumbers),
      ),
    )
    .orderBy(desc(polkadotExtrinsicTable.timestamp))

  return {
    extrinsics: extrinsicRows.map((row) => ({
      blockNumber: row.blockNumber,
      extrinsicIndex: row.extrinsicIndex ?? undefined,
      timestamp: row.timestamp.getTime(),
    })),
  }
}
