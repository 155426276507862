import { eq } from 'drizzle-orm'
import { syncTable } from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

export const getSyncTimestamp = async (params: {
  database: DatabaseWithTransaction
  key: string
}): Promise<number> => {
  const {
    database: { database },
    key,
  } = params

  const rows = await database
    .select({ timestamp: syncTable.timestamp })
    .from(syncTable)
    .where(eq(syncTable.key, key))
    .limit(1)
  if (rows.length === 0) {
    return 0
  }
  return rows[0].timestamp.getTime()
}
