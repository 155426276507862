import { apiEndpoint } from '@/environment'
import {
  getPortfolioFromLocalStorage,
  setPortfolioToLocalStorage,
} from '@/localStorage'
import type { Portfolio, PortfolioPageData, RunToDatabase } from '@/types'
import { compress } from '@/utils'
import {
  createReadFromDatabase,
  createWriteToDatabase,
  getDatabaseFile,
} from '@/utils/database'
import {
  type Portfolio as PortfolioDatabase,
  insertPortfolio,
} from '@pkg/database-portfolio'
import { migration } from './migration'

export const registerNewPortfolio = async (params: {
  portfolioCount: number
  token: string
}): Promise<PortfolioPageData> => {
  const { portfolioCount, token } = params

  // ID 生成
  const uuid = crypto.randomUUID().slice(0, 13).replace(/-/g, '')
  const name =
    portfolioCount === 0 ? 'Portfolio' : `Portfolio${portfolioCount + 1}`

  // portfolio 作成
  const currentLocalPortfolio = getPortfolioFromLocalStorage()
  setPortfolioToLocalStorage({
    uuid,
    name,
    updatedAt: 0,
    lastUsedAt: 0,
    plan: 'free',
    isWriter: true,
    isOwner: true,
  })
  const readFromDatabase = createReadFromDatabase(uuid)
  const writeToDatabase = createWriteToDatabase(uuid)
  await migration({ readFromDatabase, writeToDatabase })
  await initPortfolio({ writeToDatabase, uuid, name })
  const file = await getDatabaseFile(uuid)
  setPortfolioToLocalStorage(currentLocalPortfolio)

  // portfolio 登録 API call
  // 圧縮 (gzip)
  const gzipFile = await compress(file)
  const body = new FormData()
  body.append('file', gzipFile)
  const timestamp = Date.now()
  const url = new URL('/v1/portfolio', apiEndpoint)
  url.searchParams.set('name', name)
  url.searchParams.set('id', uuid)
  url.searchParams.set('timestamp', '0')
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
  if (!response.ok) {
    throw new Error(
      `Failed to create portfolio: ${response.status} ${response.statusText}`,
    )
  }

  const portfolio: Portfolio = {
    uuid,
    name,
    updatedAt: timestamp,
    lastUsedAt: timestamp,
    // TODO ベータ版に限り自動で mini plan を付与する
    // plan: 'free',
    plan: 'mini',
    isWriter: true,
    isOwner: true,
  }
  return {
    ...portfolio,
    readonly: false,
    readFromDatabase,
    writeToDatabase,
  }
}

const initPortfolio = async (params: {
  writeToDatabase: RunToDatabase
  uuid: string
  name: string
}) => {
  const { writeToDatabase, uuid, name } = params

  // TODO ベータ版では Asia/Tokyo 以外は選択できないようにする
  // TODO 後日 Intl API で取得したタイムゾーンによって設定を変える
  const portfolioLocale = createPortfolioLocaleJp('Asia/Tokyo')
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let portfolioLocale: PortfolioLocale
  // switch (timezone) {
  //   case 'Asia/Tokyo': {
  //     portfolioLocale = createPortfolioLocaleJp(timezone)
  //     break
  //   }
  //   default: {
  //     portfolioLocale = createPortfolioLocaleDefault(timezone)
  //     break
  //   }
  // }

  await writeToDatabase((database) =>
    insertPortfolio({
      database,
      data: {
        ...portfolioLocale,
        id: uuid,
        name,
        needPeriodEndEvaluation: false,
        isTxGenerated: true,
      },
    }),
  )
}

type PortfolioLocale = Pick<
  PortfolioDatabase,
  'fiat' | 'timezone' | 'startMonth' | 'costBasis'
>

const createPortfolioLocaleJp = (timezone: string): PortfolioLocale => ({
  fiat: 'jpy',
  timezone,
  startMonth: 1,
  costBasis: 'WACB',
})

const createPortfolioLocaleDefault = (timezone: string): PortfolioLocale => ({
  fiat: 'usd',
  timezone,
  startMonth: 1,
  costBasis: 'FIFO',
})
