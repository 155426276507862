import { type LowerHex, isHex, toLowerHex } from '@0xtorch/evm'
import { blobToHex, divideArrayIntoChunks, hexToBlob } from '@pkg/basic'
import { and, eq, inArray, or, sql } from 'drizzle-orm'
import { sqliteMaxHostParameterCount } from '../../constants'
import {
  accountEvmTxIndexRelationTable,
  accountTable,
  actionSourceTable,
} from '../../schema'
import type { DatabaseWithTransaction } from '../../types'

export const updateEvmTxAnalyzedStatus = async (params: {
  database: DatabaseWithTransaction
  addresses: readonly { chainId: number; address: string }[]
  functionIds: readonly string[]
}) => {
  const {
    database: { database, transaction },
    addresses,
    functionIds,
  } = params

  const blobFunctionIds: Uint8Array[] = []
  for (const functionId of functionIds) {
    if (!isHex(functionId)) {
      continue
    }
    blobFunctionIds.push(hexToBlob(functionId))
  }

  const chainAddresses = new Map<number, Set<LowerHex>>()
  for (const { chainId, address } of addresses) {
    if (!isHex(address)) {
      continue
    }
    const lowerAddress = toLowerHex(address)
    const addressSet = chainAddresses.get(chainId) ?? new Set()
    addressSet.add(lowerAddress)
    chainAddresses.set(chainId, addressSet)
  }

  // address, functionId に関連する action source を取得
  const [functionIdSourceRowsChunks, addressSourceRowsChunks] =
    await Promise.all([
      Promise.all(
        divideArrayIntoChunks(blobFunctionIds, sqliteMaxHostParameterCount).map(
          (chunk) =>
            database
              .selectDistinct({
                chainId: actionSourceTable.evmChainId,
                hash: actionSourceTable.evmHash,
              })
              .from(actionSourceTable)
              .where(inArray(actionSourceTable.evmFunctionId, [...chunk])),
        ),
      ),
      Promise.all(
        [...chainAddresses.entries()].map(([chainId, addressSet]) =>
          Promise.all(
            divideArrayIntoChunks(
              [...addressSet],
              Math.floor(sqliteMaxHostParameterCount / 2) - 1,
            ).map((chunk) =>
              database
                .selectDistinct({
                  chainId: actionSourceTable.evmChainId,
                  hash: actionSourceTable.evmHash,
                })
                .from(actionSourceTable)
                .where(
                  and(
                    eq(actionSourceTable.evmChainId, chainId),
                    or(
                      inArray(
                        actionSourceTable.evmFrom,
                        chunk.map((address) => hexToBlob(address)),
                      ),
                      inArray(
                        actionSourceTable.evmTo,
                        chunk.map((address) => hexToBlob(address)),
                      ),
                    ),
                  ),
                ),
            ),
          ),
        ),
      ),
    ])

  const hashSet = new Set<LowerHex>()
  for (const rows of functionIdSourceRowsChunks) {
    for (const row of rows) {
      if (row.chainId === null) {
        continue
      }
      if (row.hash === null) {
        continue
      }
      const hash = blobToHex(row.hash)
      hashSet.add(hash)
    }
  }
  for (const chainRows of addressSourceRowsChunks) {
    for (const rows of chainRows) {
      for (const row of rows) {
        if (row.chainId === null) {
          continue
        }
        if (row.hash === null) {
          continue
        }
        const hash = blobToHex(row.hash)
        hashSet.add(hash)
      }
    }
  }

  const evmAccountRow = await database
    .select({
      id: accountTable.id,
    })
    .from(accountTable)
    .where(eq(accountTable.type, 'evm'))
  const accountIds = evmAccountRow.map((row) => row.id)

  await transaction([
    (tx) => [
      // chainId, hash に一致する accountEvmTxIndexRelationTable の analyzed を false にする
      ...divideArrayIntoChunks(
        [...hashSet],
        sqliteMaxHostParameterCount - 1,
      ).map((chunk) =>
        tx
          .update(accountEvmTxIndexRelationTable)
          .set({
            analyzed: false,
          })
          .where(
            and(
              inArray(
                accountEvmTxIndexRelationTable.hash,
                chunk.map((hash) => hexToBlob(hash)),
              ),
            ),
          ),
      ),
    ],
    (tx) => [
      // account.syncing を最新データに合わせて更新
      ...accountIds.map((accountId) =>
        tx
          .update(accountTable)
          .set({
            syncing: sql`(EXISTS (SELECT 1 FROM ${accountEvmTxIndexRelationTable} WHERE ${accountEvmTxIndexRelationTable.accountId} = ${accountId} AND ${accountEvmTxIndexRelationTable.analyzed} = ${false}))`,
          })
          .where(eq(accountTable.id, accountId)),
      ),
    ],
  ])

  // デバッグ用に analyzed = false の accountEvmTxIndexRelationTable data を取得
  const relationRows = await database
    .select()
    .from(accountEvmTxIndexRelationTable)
    .where(eq(accountEvmTxIndexRelationTable.analyzed, false))
  console.debug('unanalyzed accountEvmTxIndexRelationTable data:')
  console.debug(
    relationRows.map((row) => ({
      hash: blobToHex(row.hash),
      accountId: row.accountId,
    })),
  )
}
