import { type CryptoCurrency, ethereum, pol } from '@0xtorch/core'

export const cryptoCurrencyConverter = new Map<string, CryptoCurrency>([
  ['matic-network', { ...pol, updatedAt: 0 }],
  ['weth', { ...ethereum, updatedAt: 0 }],
])

export const cryptactSymbolConverter = new Map<string, string>([
  ['AMFT', 'atletico-madrid'],
  ['CCPLT', 'palette'],
  ['MA', 'manta-network'],
  ['MONA', 'monacoin'],
  ['RNDR', 'render-token'],
])
