import {
  type Chain,
  createAssetHubChain,
  createAstarChain,
  createMantaChain,
  createMoonbeamChain,
  createPolkadotChain,
} from '@0xtorch/polkadot'

export const createPolkadotChains = ({
  explorerProxyUrl,
}: {
  explorerProxyUrl?: string
}): readonly Chain[] => [
  createAssetHubChain({
    explorerProxyUrl,
  }),
  createAstarChain({ explorerProxyUrl }),
  createMantaChain({ explorerProxyUrl }),
  createMoonbeamChain({ explorerProxyUrl }),
  createPolkadotChain({ explorerProxyUrl }),
]
